import {mapGetters} from 'vuex';
import {
	MAKE_ID,
	RANGE_ID,
	TYPE_ID,
	GEAR_ID,
	FIRST_REGISTRATION,
	QUAL_MD,
	QUAL_LT,
	IMAGE_VIN_SWISS, IMAGE_VIN, IMAGE_SWISS, IMAGE_KBA, IMAGE_VIN_KBA
} from "../../consts/vehicle";

let mixin = {
	data: () => ({
		MAKE_ID,
		RANGE_ID,
		TYPE_ID,
		GEAR_ID,
		FIRST_REGISTRATION,
		QUAL_MD,
		QUAL_LT,
		opened: {
			make_id: false,
			first_registration: false,
			range_id: false,
			type_id: false,
			gear_id: false,
			qual_md: false,
			qual_lt: false,
		}
	}),
	computed: {
		...mapGetters(["makes", "first_registration_list", "ranges", "types", "gears", "qual_md_list", "qual_lt_list", "zip", "city", "zip_list"]),
		settingShowVin() {
			return window._repair_shop.showVin;
		},
		settingShowSwissNr() {
			return window._repair_shop.showSwissNr;
		},
		settingShowKba() {
			return window._repair_shop.showKba;
		},
		years() {
			return this.$store.getters.years;
		},
		car_number: {
			get() {
				return this.$store.getters.car_number;
			},
			set(val) {
				this.$store.commit("car_number", val);
			}
		}, make_id: {
			get() {
				return this.$store.getters.make_id;
			},
			set(val) {
				this.$store.commit("make_id", val);
			}
		},
		range_id: {
			get() {
				return this.$store.getters.range_id;
			},
			set(val) {
				this.$store.commit("range_id", val);
			}
		},
		type_id: {
			get() {
				return this.$store.getters.type_id;
			},
			set(val) {
				this.$store.commit("type_id", val);
			}
		},
		first_registration: {
			get() {
				return this.$store.getters.first_registration;
			},
			set(val) {
				this.$store.commit("first_registration", val);
			}
		},
		gear_id: {
			get() {
				return this.$store.getters.gear_id;
			},
			set(val) {
				this.$store.commit("gear_id", val);
			}
		},
		qual_md: {
			get() {
				return this.$store.getters.qual_md;
			},
			set(val) {
				this.$store.commit("qual_md", val);
			}
		},
		qual_lt: {
			get() {
				return this.$store.getters.qual_lt;
			},
			set(val) {
				this.$store.commit("qual_lt", val);
			}
		},
		mileage: {
			get() {
				return this.$store.getters.mileage;
			},
			set(val) {
				this.$store.commit("mileage", val);
			}
		},
		zip: {
			get() {
				return this.$store.getters.zip;

			},
			set(val) {
				this.$store.commit("setZip", val);
			}
		},
	},
	methods: {
		labelVinSwiss() {
			if (this.settingShowVin && this.settingShowSwissNr) return this.$t('form.label.car_number_vin_swiss');
			if (this.settingShowVin && this.settingShowKba) return this.$t('form.label.car_number_vin_kba');
			if (this.settingShowVin) return this.$t('form.label.car_number_only_vin');
			if (this.settingShowSwissNr) return this.$t('form.label.car_number_only_swiss');
			if (this.settingShowKba) return this.$t('form.label.car_number_only_kba');
		},
		placeholderVinSwiss() {
			if (this.settingShowVin && this.settingShowSwissNr) return this.$t('form.placeholder.car_number_vin_swiss');
			if (this.settingShowVin && this.settingShowKba) return this.$t('form.placeholder.car_number_vin_kba');
			if (this.settingShowVin) return this.$t('form.placeholder.car_number_only_vin');
			if (this.settingShowSwissNr) return this.$t('form.placeholder.car_number_only_swiss');
			if (this.settingShowKba) return this.$t('form.placeholder.car_number_only_kba');
		},
		toggleSelect(item, payload) {
			this.opened[item] = payload;
		},
    getTooltip() {
      let tooltip = this.$t('home.vehicle.info_vin_swiss');
      let carNumber = this.getTooltipImage();

			return `${tooltip} <br> <img src="/img/vehicle/where-to-find-${carNumber}.png" style="width:100%; max-width:100%; margin-bottom:10px">`;
		},
		getTooltipImage() {
			if (this.settingShowVin && this.settingShowSwissNr) return IMAGE_VIN_SWISS;
			if (this.settingShowVin && this.settingShowKba) return IMAGE_VIN_KBA;
			if (this.settingShowVin) return IMAGE_VIN;
			if (this.settingShowSwissNr) return IMAGE_SWISS;
			if (this.settingShowKba) return IMAGE_KBA;
		},
	}
};

export default mixin;
