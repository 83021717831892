<template>
  <div>
    <div class="garage-form-box">
      <h3 v-html="$t('details.title')"></h3>
      <!--<p>-->
      <!--<strong>{{$t('details.works_parts')}} </strong>-->
      <!--<strong class="pull-right">{{$t('details.price_include_tax')}}</strong>-->
      <!--</p>-->
      <ul class="selected-services">
        <li v-for="item in selectedOfficial" :key="item.ItemMpId">
          {{item.ItemMpText}}
          <!--<span class="pull-right"> ({{Country.CURRENCY}} {{item.price}})</span>-->
        </li>
        <li v-for="item in selectedLabourSearch" :key="item.id">
          {{item.name}}
          <!--<span class="pull-right">({{Country.CURRENCY}} {{item.price}})   -->
          <!--<span v-if="item.price === 0">*</span></span>-->
          <ul>
            <li v-for="(child,index) in item.siblings" :key="child.id+index" v-if="child.workId !== ''">
              {{child.name}}
              <!--<span class="pull-right">({{Country.CURRENCY}} {{item.price}})</span>-->
            </li>
          </ul>
        </li>
        <li v-for="item in selectedLabourCustom" :key="item.id">
          {{item.name}}
          <!--<span class="pull-right">({{Country.CURRENCY}} {{item.price}})</span>-->
        </li>
      </ul>
      <!--<p>-->
      <!--{{$t('details.included_in_service')}}-->
      <!--</p>-->
      <p v-if="Object.keys(garage.discounts).filter(discount => garage.discounts[discount] > 0).length" class="start-price">
        {{$t('details.start_price')}}
        <span><strong>{{Country.CURRENCY}} {{(garage.start_price).toFixed(2)}}</strong></span>
      </p>
      <p v-if="garage.discounts.parts > 0" class="start-price">
        {{$t('details.discount_on_parts')}}
        <span><strong>{{Country.CURRENCY}} {{(garage.discounts.parts).toFixed(2)}}</strong></span>
      </p>
      <p v-if="garage.discounts.brakes > 0" class="start-price">
        {{$t('details.discount_on_brakes')}}
        <span><strong>{{Country.CURRENCY}} {{(garage.discounts.brakes).toFixed(2)}}</strong></span>
      </p>
      <p v-if="Object.keys(garage.discounts).filter(discount => garage.discounts[discount] > 0).length" class="start-price">
        {{$t('details.discount_price')}}
        <span><strong>{{Country.CURRENCY}} {{(garage.discount_price).toFixed(2)}}</strong></span>
      </p>
      <p v-else class="start-price">
        {{$t('details.start_price')}}
        <span><strong>{{Country.CURRENCY}} {{(garage.discount_price).toFixed(2)}}</strong></span>
      </p>

      <div v-if="garageText" class="garage-details-text">
        <read-more :more-str="$t('defaults.read_more')" :less-str="$t('defaults.read_less')" :text="garageDetailsText"
                   link="#" :max-chars="310"></read-more>
      </div>
    </div>
    <div class="garage-form-box">
      <div class="form-group">
        <custom-services :list="labourCustomServices"/>
      </div>
    </div>

    <div class="garage-form-box">
      <calendar :waiting="garage.avg_waiting"></calendar>
    </div>
    <div class="garage-form-box">
      <h3 v-html="$t('form.label.comment')"></h3>
      <textarea v-model="comment" name="comment" id="comment" cols="30" rows="5"
                :placeholder="$t('form.placeholder.comment')"></textarea>
    </div>
    <div class="form-group">
      <router-link :to="{name:'services'}" tag="a" class="back-link">
        <i class="fa fa-angle-left"></i>
        {{$t('form.button.back')}}
      </router-link>
      <button @click.prevent="submit" type="submit" class="pull-r">
        {{$t('form.button.next')}}
      </button>
    </div>
  </div>
</template>
<script>
	import CustomServices from "./groups/services/CustomServices";
	import Calendar from "@/components/Calendar";
    import Country from "../../helpers/Country";

	export default {
		components: {CustomServices, Calendar},
        data(){
          return {
            Country: Country
          }
        },
		computed: {
			labourCustomServices() {
				return this.$store.getters.labourCustomServices;
			},
			officialServices() {
				return this.$store.getters.officialServices;
			},
			selectedOfficial() {
				return this.$store.getters.selectedOfficial;
			},
			selectedLabourSearch() {
				return this.$store.getters.selectedLabourSearch;
			},
			selectedLabourCustom() {
				return this.$store.getters.selectedLabourCustom;
			},
			garage() {
				return this.$store.getters.garage;
			},
			garageText() {
				return window._garage_details_text;
			},
			garageDetailsText() {
				if (this.garageText) {
					return `<i class="fa fa-info-circle"></i><div class="garage-details-text-content">${this.garageText}</div>`;
				}
				return '';

			},
			comment: {
				get() {
					return this.$store.getters.comment;
				},
				set(value) {
					this.$store.commit("comment", value);
				}
			}
		},
		methods: {
			submit() {
				this.$store.dispatch("validateTermin").then(() => {
					this.$store.dispatch("validateGarage").then(() => {
						this.$store.dispatch("saveGarage").then(() => {
							this.$emit("submit");
						}).catch(error => {
              this.$emit("error", error);
						});
					}).catch(error => {
						this.$scrollTo('#custom-services');
						this.$toastr("error", this.$t('details.services.required'), this.$t("toastr.error"));
					});
				}).catch(error => {
					this.$scrollTo('#timepicker');
					this.$toastr("error", this.$t('details.terminpicker.required'), this.$t("toastr.error"));
				});
			}
		}
	};
</script>