<template>
  <div class="container">
    <div class="row">
      <div class="form-widget-content">
        <div class="col-md-6 widget-container">
          <widget></widget>
        </div>
        <div class="col-md-6">
          <steps></steps>

          <div v-if="canShowLP && showByUserLP">
            <licence-plate @submit="submit"></licence-plate>
          </div>

          <div v-else>
            <vehicle-form @submit="submit"></vehicle-form>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
	import VehicleForm from '@/components/forms/Vehicle';
	import LicencePlate from '@/components/forms/LicencePlate';
	import Widget from '@/components/layout/Widget';
	import Steps from '@/components/layout/Steps';
	import store from '../store/store';
	import {lang} from '@/lang';
	import {mapGetters} from 'vuex';

	export default {
		page: {
			title: lang[store.getters.language].title.home,
		},
		components: {VehicleForm, Widget, Steps, LicencePlate},
		created() {
		    if(this.$store.getters.offerLoaded){
                this.$store.dispatch('setStep', 0);
            }
		},
		computed: {
			...mapGetters(['multipleVehicleLP', 'showByDefaultLP', 'canShowLP', 'showByUserLP']),
			// showVehicleForm() {
			// 	return this.showByDefaultLP === false && this.showByUserLP === false;
			// }
		},
		methods: {
			submit() {
				this.$router.push({name: 'services'});
			}
		}
	}
</script>