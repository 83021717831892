import Vue from 'vue';
import {GET_OFFER} from "@/consts/url";

export const actions = {
    getOffer: ({state, rootState, commit, dispatch, getters}) => {
        commit('loading', true);

        return Vue.axios[GET_OFFER.method](GET_OFFER.name, state.user).then(response => {

            let result = response.data;

            commit('setOfferLoaded', true);

            commit('setCar', result.car);
            commit('setOfficial', result.official);
            commit('setLabourSearch', result.labour.search);
            commit('setLabourCustom', result.labour.custom);

            if(!getters.isSelectedOfficialServices){
                dispatch('submitPreselectedServices', rootState.preselected.services);
            }

            commit('setGarage', result.garage);

            commit('setUser', result.user);

            commit('setShowByUserLP', false);

            commit('setLeadsVerified', result.user_verified);

            dispatch('setStep', result.step_current);

        }).finally(() => {
            commit('loading', false);
        });

    },
};