import Vue from 'vue';
import Vuex from 'vuex';
import resetCar from '@/store/plugins/resetCar';
import resetAddress from '@/store/plugins/resetAddress';
import filterMakes from "@/store/plugins/filterMakes";
import toggleLabourIfOfficial from "@/store/plugins/toggleLabourIfOfficial";
import {car, address, services, garages, user, licencePlate, IRSRToken, leads, offer} from './modules';
import {STEP} from "@/consts/url";
import SessionStorage from "../helpers/SessionStorage";
import carFactory from '@/factory/car';
import addressFactory from '@/factory/address';
import garagesFactory from '@/factory/garages';
import licencePlateFactory from '@/factory/licence-plate';
import servicesFactory from '@/factory/services';
import userFactory from '@/factory/user';
import IRSRTokenFactory from '@/factory/IRSRToken';
import leadsFactory from '@/factory/leads';
import offerFactory from '@/factory/offer';

Vue.use(Vuex);

const getDefaultState = () => {
	return {
		loading: {
			global: false,
			form: false,
		},
		language: window._language,
		isServicesExist: true,
		step: 0,
		preselected: {
			services: {
				custom: [],
				search: [],
				official: window._settings.preselected_official_services,
			},
			car: {
				makes: [],
				first_registration_list: [],
				ranges: [],
				types: [],
			}
		},
		car: {car: carFactory()},
		address: {address: addressFactory()},
		services: servicesFactory(),
		garages: garagesFactory(),
		user: {user: userFactory()},
		licencePlate: {licence_plate: licencePlateFactory()},
		IRSRToken: {IRSRToken: IRSRTokenFactory()},
		leads: {leads: leadsFactory()},
		offer: {offer: offerFactory()},
	}
}

export default new Vuex.Store({
	plugins: [resetCar, resetAddress, filterMakes, toggleLabourIfOfficial], // ,createPersistedState({key: 'data'})
	modules: {car, address, services, garages, user, licencePlate, IRSRToken, leads, offer},
	state: getDefaultState(),

	getters: {
		loading: state => {
			return state.loading.global;
		},
		loadingForm: state => {
			return state.loading.form;
		},
		step: state => {
			return state.step;
		},
		language: state => {
			return state.language;
		},
		preselectedServices: state => {
			return state.preselected.services;
		},
		preselectedCar: state => {
			return state.preselected.car;
		},
	},

	mutations: {
		loading: (state, payload) => {
			state.loading.global = payload;
		},
		loadingForm: (state, payload) => {
			state.loading.form = payload;
		},
		step: (state, payload) => {
			state.step = payload;
		},
		preselectedServices: (state, payload) => {
			state.preselected.services = payload;
		},
		preselectedCar: (state, payload) => {
			state.preselected.car = payload;
		},
		isServicesExist: (state, payload) => {
			state.isServicesExist = payload;
		},
		resetState (state) {
			Object.assign(state, getDefaultState())
		}
	},

	actions: {
		setStep: ({state, commit, getters}, payload) => {

			if (SessionStorage.getItem('offer_token') || getters.token) {
				commit('step', payload);
				return Vue.axios[STEP.method](STEP.name, {step: state.step});
			}

			let repairShopName = window._repair_shop.slug;

			if (payload === 0 || payload === 1) {
				window.fbq('track', 'ViewContent', {
					content_ids: repairShopName,
					content_type: 'service',
				});
			} else if (payload === 2 || payload === 3) {
				window.fbq('track', 'InitiateCheckout', {});
			} else if (payload === 4) {
				window.fbq('track', 'Purchase', {
					contents: [
						{
							quantity: 1,
							id: 'service'
						}
					],
					content_ids: repairShopName,
				});
			}

		},
		setLanguage: ({state}, payload) => {
			window.location = `${window._url}?lang=${payload}`;
		},
		resetState ({ commit }) {
			commit('resetState')
		},
	},
});