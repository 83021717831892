const MAKE_ID = 'make_id';
const RANGE_ID = 'range_id';
const TYPE_ID = 'type_id';
const GEAR_ID = 'gear_id';
const FIRST_REGISTRATION = 'first_registration';
const QUAL_MD = 'qual_md';
const QUAL_LT = 'qual_lt';
const VIN_STRING = 'VIN';
const SWISS_STRING = 'Swiss Number';
const KBA_STRING = 'KBA Number';
const COLOR_ID = 'COLOR_ID';

const IMAGE_VIN_SWISS = 'swiss-or-vin';
const IMAGE_VIN = 'vin';
const IMAGE_SWISS = 'swiss';
const IMAGE_VIN_KBA = 'kba-or-vin';
const IMAGE_KBA = 'kba';

export {
	VIN_STRING,
	SWISS_STRING,
	KBA_STRING,
	MAKE_ID,
	COLOR_ID,
	RANGE_ID,
	TYPE_ID,
	GEAR_ID,
	FIRST_REGISTRATION,
	QUAL_MD,
	QUAL_LT,

	IMAGE_VIN_SWISS,
	IMAGE_VIN,
	IMAGE_SWISS,
	IMAGE_VIN_KBA,
	IMAGE_KBA,
}