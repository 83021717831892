<template>
    <div class="container">
        <div class="row">
            <div class="form-widget-content">
                <div class="col-md-6 widget-container">
                    <widget></widget>
                </div>
                <div class="col-md-6">
                    <steps></steps>
                    <p v-html="$t('pages.service_title')"></p>
                    <services-form @submit="submit" @error="error"></services-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ServicesForm from '@/components/forms/Service';
    import Widget from '@/components/layout/Widget';
    import Steps from '@/components/layout/Steps';
    import store from '../store/store';
    import {lang} from '@/lang';

    export default {
        page: {
            title: lang[store.getters.language].title.services,
        },
        components: {ServicesForm, Widget, Steps},
        created() {
            this.$store.dispatch('setStep', 1);
            this.$scrollTo('.steps');
        },
        methods: {
            submit() {
                if(window._repair_shop.leadAccess && this.$store.getters.routeLeadsUserVerified === false){
                    this.$router.push({name: 'leads'});
                }
                else {
                    this.$router.push({name: 'details'});
                }
            },
            error(error) {
                this.$toastr("error", error.response.data.message, this.$t("toastr.error"));

                if (error.response.status === 424) {
                    this.$router.push({name: 'home'});
                    this.$store.dispatch('resetState');
                }
            }
        }
    }
</script>