let mixin = {
    methods: {
        DDE(event) {
            preventCodes.includes(event.charCode) ? event.preventDefault() : null;
        }
    }
};

const preventCodes = [45, 46, 101]; // ['.','e','-']

export default mixin;
