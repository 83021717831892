<template>
  <div v-if="loadingForm" class="loading">
        <span>
          <i class="fa fa-spinner"></i>
        </span>
  </div>
</template>
<script>
  export default {
  	computed:{
  		loadingForm(){
  			return this.$store.getters.loadingForm;
      }
    }
  }
</script>