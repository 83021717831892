import Vue from 'vue';
import {RepairShopNotServicingCar} from '../../../exceptions/RepairShopNotServicingCar';
import {CarDataNotExisting} from '../../../exceptions/CarDataNotExisting';
import {GET_LICENCE_PLATE, MULTIPLE_VEHICLE_CHOICE} from "../../../consts/url";
import {CarDataForLPNotExisting} from "../../../exceptions/CarDataForLPNotExisting";
import SessionStorage from "../../../helpers/SessionStorage";

const NO_VEHICLE = -1;
const ONE_VEHICLE = 1;
const MULTIPLE_VEHICLE = 2;


export const actions = {

	goBackFromMultipleVehicle: ({state, rootState, commit}) => {
		commit('setMultipleVehicleLP', false);
		commit('setStatusLP', null);
	},
	goBackFromHalfVehicle: ({state, rootState, commit}) => {
// TODO
	},

	goManualVehicle: ({state, commit}) => {
		commit('resetCar');
		commit('setShowByUserLP', false);
		SessionStorage.removeItem('offer_token');
	},


	getCarByLicencePlate: ({state, rootState, commit}) => {
		SessionStorage.removeItem('offer_token');
		commit('loadingForm', true);
		commit('resetCar');
		commit('licence_plate', state.licence_plate.data);

		let data = {...rootState.car.car, zip_id: rootState.address.address.id};

		return Vue.axios[GET_LICENCE_PLATE.method](GET_LICENCE_PLATE.name, data)
			.then(response => {
				let result = response.data;
				if (result.status === NO_VEHICLE) {
					throw new RepairShopNotServicingCar('Dieses Fahrzeug kann nicht gefunden werden. Bitte überprüfen Sie und versuchen Sie es erneut.');
				} else {
					commit('setStatusLP', result.status);
					commit('setExistsLPCar', result.car);
					commit('setAttemptsLP', result.attempts);

					if (result.status === MULTIPLE_VEHICLE) {
						commit('makes', result.car.makes);
						commit('make_id', result.car.make_id);
						commit('colors', result.car.colors);
						commit('color_id', result.car.color_id);
						commit('setMultipleVehicleLP', true);
					} else if (result.status === ONE_VEHICLE) {
						SessionStorage.setItem('offer_token', result.car.token);
						if (!result.car.car_service_exist) {
							commit('isServicesExist', result.car.car_service);
						}
					}

					commit('setCar', result.car);
				}
			})
			.catch(error => {
				throw new RepairShopNotServicingCar('Dieses Fahrzeug kann nicht gefunden werden. Bitte überprüfen Sie und versuchen Sie es erneut.');
				// throw new RepairShopNotServicingCar(error.response.data.message);
			})
			.finally(() => {
				commit('loadingForm', false);
				if (!rootState.isServicesExist) {
					throw new CarDataNotExisting('Car data not exist.');
				}
				if (state.licence_plate.status === NO_VEHICLE) {
					throw new CarDataForLPNotExisting();
				}
			});
	},
	getCarByMakeAndColor: ({state, rootState, commit}) => {
		commit('loadingForm', true);
		// commit('licence_plate', rootState.licence_plate.data);

		let data = {...rootState.car.car, zip_id: rootState.address.address.id};
		return Vue.axios[MULTIPLE_VEHICLE_CHOICE.method](MULTIPLE_VEHICLE_CHOICE.name, data)
			.then(response => {
				let result = response.data;

				SessionStorage.setItem('offer_token', result.car.token);
				commit('setExistsLPCar', result.car);
				commit('setStatusLP', result.status);
				commit('setCar', result.car);

			}).catch(error => {
				// throw new RepairShopNotServicingCar(error.response.data.message); // TODO setuj pravu poruku za range
				//  koji se ne servisira ili make
			}).finally(() => {
				commit('loadingForm', false);
			})
	}
};