const moment = require('moment');
// require('moment/locale/de');

moment.fn.addWorkdays = function (days) {
    let increment = days / Math.abs(days);
    let date = this.clone().add(Math.floor(Math.abs(days) / 5) * 7 * increment, 'days');
    let remaining = days % 5;
    while (remaining != 0) {
        date.add(increment, 'days');
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7)
            remaining -= increment;
    }
    return date;
};

moment.fn.subtractWorkdays = function (days) {
    let increment = days / Math.abs(days);
    let date = this.clone().subtract(Math.floor(Math.abs(days) / 5) * 7 * increment, 'days');
    let remaining = days % 5;
    while (remaining != 0) {
        date.subtract(increment, 'days');
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7)
            remaining -= increment;
    }
    return date;
};