const unorm = require('unorm');

let mixin = {
    data() {
        return {
            term: '',
        }
    },

    computed: {
        name() {
            let tempVal = this.value;
            let item = this.list.find(item => item.id === tempVal);
            if (item && item.productionFrom) {
                return `${item.name} ${item.productionFrom} - ${item.productionTo ? item.productionTo : '...'}`;
            }


            return item ? item.name : '';
        }
    },

    watch: {
        opened: function (isOpened) {
            if (isOpened) {
                window.addEventListener('keyup', this.scrollToData);
            } else {
                window.removeEventListener('keyup', this.scrollToData, true);
                this.term = '';
            }
        },
    },

    destroyed() {
        window.removeEventListener('keyup', this.scrollToData);
        this.term = '';
    },

    methods: {
        scrollToData(e) {
            let data = [];

            if (e.key === 'Escape') {
                this.toggle();
            } else if (e.key === 'Backspace') {
                this.term = this.term.slice(0, -1);
            } else if (e.key) {
                this.term += (unorm.nfd(e.key.toLowerCase())).replace(/[\u0300-\u036f]/g, "");
                data = this.list.filter(item => {
                    let name = (item.name).toString();
                    if (name) {
                        return (unorm.nfd(name.toLowerCase()).replace(/[\u0300-\u036f]/g, "")).startsWith(this.term)
                    }
                    return null;
                });

                if (data.length) {
                    let select = this.$refs.vselect;
                    if (this.$refs[data[0]['id']] && this.$refs[data[0]['id']][0]) {
                        select.scrollTop = this.$refs[data[0]['id']][0].offsetTop;
                        //TODO add className to li - visible selected (entered) make, range or type
                    }
                } else {
                    this.term = '';
                }
            }
        }
    }
};

export default mixin;
