export const getters = {
    garages: state => state.garages,
    garage: state => state.garage,
    booking_dates: state => state.booking_dates,
    routeGarageExist: (state, getters) => {
        return Object.keys(getters.garage).length;
    },
    routeBookingDatesExist: (state, getters) => {
        return Object.keys(getters.booking_dates).length;
    }
};