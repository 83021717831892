<template>
  <div v-on-click-outside="toggle" class="v-select" :class="{disabled: disabled}">
    <p v-if="value">{{name}} <i v-if="list.length > 1" class="fa fa-angle-down"></i></p>
    <p v-else>{{placeholder}} <i class="fa fa-angle-down"></i></p>
    <div v-if="list.length > 1">
      <div v-if="opened" class="list-item-container">
        <div class="parent-list-items">
          <div class="list-placeholder">{{placeholder}}
            <div class="close-icon" @click.stop="toggle"><i class="fa fa-close"></i></div>
          </div>
          <ul ref="vselect">
            <li v-for="(item,index) in list" :key="index" :class="{selected: item.id === value}"
                @click="set(item)" :ref="item.id">
              {{item.name}}
              <small v-if="item.hasOwnProperty('productionFrom')">{{item.productionFrom}} - {{item.productionTo ? item.productionTo : '...'}}
              </small>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

	import VSelect from "@/mixins/components-di/VSelect";

	export default {
		mixins: [VSelect],
		inject: ["$validator"],

		props: {
			value: [String, Number],
			list: Array,
			opened: Boolean,
			type: String,
			placeholder: String,
			disabled: Boolean
		},

		methods: {
			toggle() {
				if (this.list.length) {
					this.$emit("toggle", this.type);
				}
			},

			set(item) {
				this.$emit("input", item.id);
				this.$emit("change", {type: this.type, id: item.id});
			},
		}
	};
</script>