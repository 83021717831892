<template>
  <div>
    <div v-if="statusLP === null || statusLP === 2">
      <licence-plate-box v-if="!multipleVehicle"></licence-plate-box>
      <multiple-vehicle-box v-else></multiple-vehicle-box>
    </div>

    <vehicle-box v-else-if="statusLP === 1 || statusLP === -1" @submit="submit"></vehicle-box>
  </div>
</template>
<script>
	import LicencePlateBox from '@/components/forms/licence-plate/LicencePlateBox';
	import MultipleVehicleBox from '@/components/forms/licence-plate/MultipleVehicleBox';
	import VehicleBox from '@/components/forms/licence-plate/VehicleBox';

	export default {
		components: {LicencePlateBox, MultipleVehicleBox, VehicleBox},
		computed: {
			multipleVehicle: {
				get() {
					return this.$store.getters.multipleVehicleLP;
				}
			},
			statusLP: {
				get() {
					return this.$store.getters.statusLP;
				}
			},
		},
    methods:{
			submit(){
				this.$emit("submit");
      }
    }
	}
</script>