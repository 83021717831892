import Services from '@/views/Services';
import Garage from '@/views/Garage';
import Home from '@/views/Home';
import User from '@/views/User';
import ThankYou from '@/views/ThankYou';
import PageNotFound from '@/views/PageNotFound';
import Leads from '@/views/Leads';
import store from '../store/store';
import {lang} from '@/lang';

let routes = [
    {
        path: lang[store.getters.language].routes.home,
        name: 'home',
        component: Home,
        meta: {},
        props: {
            redirect: {name: 'home'},
        }
    },
    {
        path: lang[store.getters.language].routes.services,
        name: 'services',
        component: Services,
        meta: {
            requestOfferToken: true,
            requestCarData: true,
            requestServiceData: true,
        },
        props: {
            redirect: {name: 'home'},
        }
    },
    {
        path: lang[store.getters.language].routes.details,
        name: 'details',
        component: Garage,
        meta: {
            requestOfferToken: true,
            requestGarageData: true
        },
        props: {
            redirect: {name: 'services'},
        }
    },
    {
        path: lang[store.getters.language].routes.user,
        name: 'user',
        component: User,
        meta: {
            requestOfferToken: true,
            requestBookingDatesData: true,
            requestIRSRToken: true,
        },
        props: {
            redirect: {name: 'details'},
        }
    },
    {
        path: lang[store.getters.language].routes.thanks,
        name: 'thanks',
        component: ThankYou,
        meta: {
            requestOfferToken: true,
            requestUserData: true,
        },
        props: {
            redirect: {name: 'user'},
        }
    },
    {
        path: '*',
        name: 'page-not-found',
        component: PageNotFound
    },

];

if(window._repair_shop.leadAccess){
    routes.splice(routes.findIndex((route) => route.name === 'details'), 0, {
        path: lang[store.getters.language].routes.leads,
        name: 'leads',
        component: Leads,
        meta: {
            requestOfferToken: true,
            requestCarData: true,
            requestServiceData: true,
            requestHasLeadsAccess: true,
        },
        props: {
            redirect: {name: 'services'},
        }
    })

    routes.map(route => {
        if(route.name === 'details'){
            route.meta.requestUserVerified = true
            route.props.redirect.name = 'leads'
        }

        return route;
    })

}

export default routes;