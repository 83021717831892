<template>
  <div class="vehicle-info">
    <div v-if="showCar()">
      <p><strong>{{$t('home.licence_plate.found_vehicle')}}</strong></p>
      <div>
        <div class="logo">
          <img :src="getLogo()" :alt="make">
        </div>
        <div class="vehicle-info--info">
          {{make}} <br>
          {{range}} <br>
          {{type}} <br>
        </div>
      </div>
      <div v-if="checkCarFields()">
        {{$t('home.licence_plate.provide_half_vehicle')}}
      </div>
    </div>
    <div v-else>
      {{$t('home.licence_plate.provide_full_vehicle')}}
    </div>
  </div>
</template>
<script>
	import {mapGetters} from 'vuex';
	import str_slug from "../../../helpers/str_slug";

	export default {
		computed: {
			...mapGetters(['make', 'range', 'type', 'existLPCar', 'gears', 'qual_md_list', 'qual_lt_list']),
		},
		methods: {
			showCar() {
				return !this.existLPCar.make_id && !this.existLPCar.range_id && !this.existLPCar.type_id;
			},
			getLogo() {
				return `/img/car-makes/${str_slug(this.make)}.png`;
			},
			checkCarFields() {
				if (this.gears.length > 1) {
					return true;
				}

				if (this.gears.length) {
					if (this.qual_md_list.length > 1 || this.qual_lt_list.length > 1) {
						return true;
					}
				}

				return false;
			}
		}
	}
</script>