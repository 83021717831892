import {KBA_STRING, SWISS_STRING, VIN_STRING} from "@/consts/vehicle";
import car from '@/factory/car';

export const mutations = {
	car_number: (state, payload) => {
		state.car.car_number = payload.replace(/\s/g, "");

		if (isNaN(parseInt(state.car.car_number.charAt(0)))) {
			state.car.car_number_type = VIN_STRING;
		}
		else {
			state.car.car_number_type = window._repair_shop.showSwissNr ? SWISS_STRING : KBA_STRING;
		}
	},
	licence_plate: (state, payload) => {
		state.car.licence_plate = payload.toUpperCase();
	},
	make_id: (state, payload) => {
		state.car.make_id = payload;
	},
	range_id: (state, payload) => {
		state.car.range_id = payload;
	},
	type_id: (state, payload) => {
		state.car.type_id = payload;
	},
	first_registration: (state, payload) => {
		state.car.first_registration = payload;
	},
	first_registration_list: (state, payload) => {
		state.car.first_registration_list = payload;
	},
	gear_id: (state, payload) => {
		state.car.gear_id = payload;
	},
	qual_md: (state, payload) => {
		state.car.qual_md = payload;
	},
	qual_lt: (state, payload) => {
		state.car.qual_lt = payload;
	},
	mileage: (state, payload) => {
		state.car.mileage = payload;
	},
	color_id: (state, payload) => {
		state.car.color_id = payload;
	},
	colors: (state, payload) => {
		state.car.colors = payload;
	},
	resetCar: (state) => {
		state.car = {...car(), mileage: state.car.mileage};
	},
	setCar: (state, payload) => {
		let colors = car().colors;

		if (payload.colors) {
			colors = payload.colors;
		}

		state.car = {...payload, colors: colors};
	},
	makes: (state, payload) => {
		state.car.makes = payload;
	},
	token: (state, payload) => {
		state.car.token = payload;
	},
};