let services = () => {
    return {
        services: {
            official: {
                prev: [],
                next: [],
                show: 0, // 0 -> prev, 1 -> next
                waiting: false,
                prevKm: 0,
                nextKm: 0,
            },
            labour: {
                search: [],
                custom: []
            },
        },
        search: {
            term: '',
            temp_custom: [],
            temp_search: []
        }
    }
};

export default services;