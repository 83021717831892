<template>
  <div class="vehicle-form">
    <!--    <p>Message</p>-->
    <div class="vehicle-form-box">
      <loader></loader>
      <vehicle-info></vehicle-info>

      <div class="licence-plate-vehicle">
        <div v-if="checkVisibility('make_id')" class="form-group has-feedback"
             :class="hasError('make_id')"
             @click.stop="toggleSelect('make_id', !opened.make_id)">
          <label for="make_id" v-html="$t('form.label.make')"></label>

          <v-select name="make_id" :list="makes" id="make_id" :value="make_id"
                    :opened="opened.make_id" :type="MAKE_ID" :disabled="!makes.length"
                    :placeholder="$t('form.placeholder.make')" v-validate="'required'" v-model="make_id"
                    @toggle="toggleSelect('make_id', false)" @change="getData"/>

          <div v-if="hasError('make_id')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{errors.first('make_id')}}</small>
          </div>
        </div>

        <div v-if="checkVisibility('range_id')" class="form-group has-feedback" :class="hasError('range_id')"
             @click.stop="toggleSelect('range_id', !opened.range_id)">
          <label for="range_id" v-html="$t('form.label.range')"></label>

          <v-select name="range_id" :list="ranges" id="range_id" :value="range_id"
                    :opened="opened.range_id" :type="RANGE_ID" :disabled="!ranges.length"
                    :placeholder="$t('form.placeholder.range')" @toggle="toggleSelect('range_id', false)"
                    @change="getData" v-validate="'required'" v-model="range_id"/>
          <div v-if="hasError('range_id')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{errors.first('range_id')}}</small>
          </div>
        </div>

        <div v-if="checkVisibility('type_id')" class="form-group has-feedback" :class="hasError('type_id')"
             @click.stop="toggleSelect('type_id', !opened.type_id)">
          <label for="type_id" v-html="$t('form.label.type')"></label>

          <v-select name="type_id" :list="types" id="type_id" :value="type_id"
                    :opened="opened.type_id" :type="TYPE_ID" :disabled="!types.length"
                    :placeholder="$t('form.placeholder.type')" @toggle="toggleSelect('type_id', false)"
                    @change="getData" v-validate="'required'" v-model="type_id"/>
          <div v-if="hasError('type_id')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{errors.first('type_id')}}</small>
          </div>
        </div>
        <div v-if="checkVisibility('first_registration')" class="form-group has-feedback"
             :class="hasError('first_registration')"
             @click.stop="toggleSelect('first_registration', !opened.first_registration)">
          <label for="first_registration" v-html="$t('form.label.first_registration')"></label>

          <v-select name="first_registration" :list="first_registration_list" id="first_registration" :value="first_registration"
                    :opened="opened.first_registration" :type="FIRST_REGISTRATION"
                    :placeholder="$t('form.placeholder.first_registration')"
                    @toggle="toggleSelect('first_registration', false)"
                    @change="getData" v-validate="'required'" v-model="first_registration"/>

          <div v-if="hasError('first_registration')">
            <span class="form-control-feedback"></span>
            <small class="help-block" v-html="errors.first('first_registration')"></small>
          </div>
        </div>

        <div v-if="checkVisibility('gear_id')"
             class="form-group has-feedback"
             :class="hasError('gear_id')" @click.stop="toggleSelect('gear_id', !opened.gear_id)">
          <label for="gear_id" v-html="$t('form.label.gear')"></label>

          <v-select name="gear_id" :list="gears" id="gear_id" :value="gear_id"
                    :opened="opened.gear_id" :type="GEAR_ID" :disabled="!gears.length"
                    :placeholder="$t('form.placeholder.gear')" @toggle="toggleSelect('gear_id', false)"
                    @change="getData" v-validate="'required'" v-model="gear_id"/>
          <div v-if="hasError('gear_id')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{errors.first('gear_id')}}</small>
          </div>
        </div>

        <div v-if="checkVisibility('qual_md')" class="form-group has-feedback"
             :class="hasError('qual_md')" @click.stop="toggleSelect('qual_md', !opened.qual_md)">
          <label for="qual_md" v-html="$t('form.label.qual_md')"></label>

          <v-select name="qual_md" :list="qual_md_list" id="qual_md" :value="qual_md"
                    :opened="opened.qual_md" :type="QUAL_MD" :disabled="!qual_md_list.length"
                    :placeholder="$t('form.placeholder.qual_md')" v-validate="'required'" v-model="qual_md"
                    @toggle="toggleSelect('qual_md', false)" @change="getData"/>

          <div v-if="hasError('qual_md')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{errors.first('qual_md')}}</small>
          </div>
        </div>

        <div v-if="checkVisibility('qual_lt')" class="form-group has-feedback"
             :class="hasError('qual_lt')" @click.stop="toggleSelect('qual_lt', !opened.qual_lt)">
          <label for="qual_lt" v-html="$t('form.label.qual_lt')"></label>

          <v-select name="qual_lt" :list="qual_lt_list" id="qual_lt" :value="qual_lt"
                    :opened="opened.qual_lt" :type="QUAL_LT" :disabled="!qual_lt_list.length"
                    :placeholder="$t('form.placeholder.qual_lt')" v-validate="'required'" v-model="qual_lt"
                    @toggle="toggleSelect('qual_lt', false)" @change="getData"/>

          <div v-if="hasError('qual_lt')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{errors.first('qual_lt')}}</small>
          </div>
        </div>
      </div>
    </div>
    <p v-if="statusLP !== -1" class="astra-text">
      {{$t('home.licence_plate.source_provider')}}
    </p>

    <div class="form-group">
      <a href="javascript:void(0)" @click.prevent="back" class="back-link">
        <i class="fa fa-angle-left"></i>
        {{$t('form.button.back')}}
      </a>
      <button @click="submit" class="pull-r">{{$t('form.button.next')}}</button>

    </div>

  </div>
</template>
<script>
	import {mapGetters} from 'vuex';
	import Vehicle from '../../../mixins/components-di/Vehicle';
	import VSelect from '../inputs/general/VSelect';
	import InputHasError from "@/mixins/InputHasError";
	import InputMaxLength from "@/mixins/InputMaxLength";
	import DisableDotExponential from "@/mixins/DisableDotExponential";
	import VehicleInfo from './VehicleInfo';
	import Loader from '@/components/Loader';


	export default {
		components: {VSelect, VehicleInfo, Loader},
		mixins: [Vehicle, InputHasError, InputMaxLength, DisableDotExponential],
		created() {
			this.$store.commit('setMultipleVehicleLP', false);


		},
		data: () => {
			return {}
		},
		computed: {
			...mapGetters(['existLPCar', 'statusLP']),
			LPExist: {
				get() {
					return this.$store.getters.licence_plate
				}
			},
			isMakesVisible: {
				get() {
					return window._settings.is_makes_visible;
				}
			},
		},
		methods: {
			back() {
				this.$store.commit('setStatusLP', null);
			},
			getData() {
				this.$store.dispatch("getCar");
			},
			fieldExist(field) {
				return this.existLPCar[field];
			},
			checkVisibility(type) {
				if (type === 'make_id') {
					return this.isMakesVisible && this.fieldExist('range_id') && this.fieldExist('type_id');
				} else if (type === 'range_id') {
					return this.fieldExist('range_id');
				} else if (type === 'type_id') {
					return this.fieldExist('type_id') && this.type_id !== true;
				} else if (type === 'gear_id') {
					return this.gears.length > 1 && this.first_registration === true;
				} else if (type === 'first_registration') {
					return this.first_registration !== true;
				} else if (type === 'qual_md') {
					return this.qual_md_list.length > 1;
				} else if (type === 'qual_lt') {
					return this.qual_lt_list.length > 1;
				}
			},


			submit() {
				this.$validator.validateAll().then(data => {
					if (data) {
						this.$store.dispatch("getCar").then(() => {
							this.$store.dispatch("getServices").then(() => {
								this.$emit("submit");
							});
						}).catch(() => {
							this.$store.dispatch("getServices").then(() => {
								this.$emit("submit");
							});
						});
					}
				});
			}
		}
	}
</script>