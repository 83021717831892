<template>
  <div class="vehicle-form">
    <p>{{$t('home.licence_plate.multiple_vehicle')}}</p>
    <div class="vehicle-form-box">
      <loader></loader>
      <div class="form-group has-feedback"
           :class="hasError('make_id')"
           @click="toggleSelect('make_id', !opened.make_id)">
        <label for="make_id" v-html="$t('form.label.make')"></label>

        <v-select name="make_id" :list="makes" id="make_id" :value="make_id"
                  :opened="opened.make_id" :type="MAKE_ID"
                  :placeholder="$t('form.placeholder.make')" v-validate="'required'" v-model="make_id"
                  @toggle="toggleSelect('make_id', false)"/>

        <div v-if="hasError('make_id')">
          <span class="form-control-feedback"></span>
          <small class="help-block"> {{errors.first('make_id')}}</small>
        </div>

      </div>
      <div class="form-group has-feedback"
           :class="hasError('color_id')"
           @click="toggleSelect('color_id', !opened.color_id)">
        <label for="make_id" v-html="$t('form.label.color')"></label>

        <v-select name="color_id" :list="colors" id="color_id" :value="color_id"
                  :opened="opened.color_id" :type="COLOR_ID"
                  :placeholder="$t('form.placeholder.color')" v-validate="'required'" v-model="color_id"
                  @toggle="toggleSelect('color_id', false)"/>

        <div v-if="hasError('color_id')">
          <span class="form-control-feedback"></span>
          <small class="help-block"> {{errors.first('color_id')}}</small>
        </div>

      </div>
    </div>

    <div class="form-group">
      <a href="javascript:void(0)" @click.prevent="goBack"  class="back-link">
        <i class="fa fa-angle-left"></i>
        {{$t('form.button.back')}}
      </a>
      <button @click.stop="submit" class="pull-r">{{$t('form.button.next')}}</button>
    </div>

  </div>
</template>

<script>
	import {MAKE_ID, COLOR_ID} from "@/consts/vehicle";
	import VSelect from "@/components/forms/inputs/general/VSelect";
	import InputHasError from "@/mixins/InputHasError";
	import Loader from '@/components/Loader';

	export default {
		components: {VSelect, Loader},
		mixins: [InputHasError],
		computed: {
			makes: {
				get() {
					return this.$store.getters.makes;
				},
			},
			colors: {
				get() {
					return this.$store.getters.colors;
				},
			},
			make_id: {
				get() {
					return this.$store.getters.make_id;
				},
				set(val) {
					this.$store.commit("make_id", val);
				}
			},
			color_id: {
				get() {
					return this.$store.getters.color_id;
				},
				set(val) {
					this.$store.commit("color_id", val);
				}
			},

		},
		data: () => ({
			MAKE_ID,
			COLOR_ID,
			opened: {
				make_id: false,
				color_id: false
			}
		}),
		methods: {
			toggleSelect(item, payload) {
				this.opened[item] = payload;
			},
			goBack() {
				this.$store.dispatch('goBackFromMultipleVehicle');
			},
			submit() {
				this.$validator.validateAll();
				for (let item in this.fields) {
					if (!this.fields[item].valid) {
						this.$scrollTo(`#${item}`);
					}
				}

				this.$validator.validateAll().then(data => {
					if (data) {
						this.$store.dispatch('getCarByMakeAndColor');
					}
				});
			}
		}
	}
</script>