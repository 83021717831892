import car from '../../factory/car';

const resetCar = store => {
	store.subscribe((mutation, state) => {
		let type = mutation.type;
		let passed = false;

		Object.keys(state.car.car).forEach(key => {
			if (key === type) passed = true;
			if (isDisallowed(state, key)) {
				if (passed && isAllowedForChange(key) && type !== key) {
					state.car.car[key] = car()[key];
				}

			}
		})
	})
};

const isAllowedForChange = (key) => {
	return ['license_plate', 'car_number', 'car_number_type', 'mileage', 'makes', 'first_registration_list', 'ranges', 'types', 'gears', 'qual_md_list', 'qual_lt_list'].includes(key) === false;
};

const isDisallowed = (state, key) => {
	let fields = {
		make_id: () => {
			return true
		},
		first_registration: (state) => {
			return state.car.car.first_registration !== true;
		},
		range_id: (state) => {
			return !state.car.car.car_number;
		},
		type_id: (state) => {
			if(state.car.car.car_number){
				return false;
			}
			else if(state.car.car.type_id === true){
				return false;
			}

			return true;
		},
		colors: () => {
			return false
		}
	}

	return fields[key] ? fields[key](state) : true;
}

export default resetCar;