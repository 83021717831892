<template>
    <div class="not-found-official">
        <div v-if="carAge <= 1" v-html="$t('services.official.not_found_young', {make:make, mileage:mileage})"></div>
        <div v-else v-html="$t('services.official.not_found', {make:make, mileage:mileage})"></div>
    </div>
</template>
<script>
    export default {
        props: {
            make: [String],
            mileage: [Number, String],
            carAge: [Number, String]
        }
    }
</script>