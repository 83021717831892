export default {
	header: {},
	footer: {
		privacy: 'Privacy policy',
		agb: 'Terms and conditions'
	},
	currency: 'CHF',
	routes: {
		home: '/',
		services: '/services',
		leads: '/service-price',
		details: '/termin',
		user: '/user',
		thanks: '/thank-you',
	},
	title: {
		home: 'Select car',
		services: 'Choose service',
		garage: 'Choose appointment',
		user: 'Contact information',
	},
	pages: {
		vehicle_title: 'Please select your car:',
		service_title: 'Please select desired services or works:',
	},
	toastr: {
		info: 'Info',
		warning: 'Warning',
		success: 'Success',
		error: 'Error',
	},

	defaults: {
		read_more: 'Read more',
		read_less: 'Read less'
	},

	form: {
		placeholder: {
			car_number_only_vin: 'Enter VIN',
			car_number_only_swiss: 'Enter swiss type number',
			car_number_only_kba: 'Enter KBA number',
			car_number_vin_swiss: 'Please enter swiss type number or VIN',
			car_number_vin_kba: 'Please enter KBA number or VIN',
			vin: 'Please enter swiss type number',
			swiss: 'Please enter VIN',
			licence_plate: 'ZH18341',
			make: 'Please select your car make.',
			range: 'Please select your model',
			type: 'Please select your type',
			gear: 'Please choose your gearbox',
			first_registration: 'Please pick a vehicle production year',
			qual_md: 'Please select car features',
			qual_lt: 'Please select car type of structure',
			mileage: 'Mileage',
			color: 'Color',
			search_term: 'Service search...',

			first_name: 'Please enter your first name',
			last_name: 'Please enter your last name',
			email: 'Please enter your email',
			phone: 'Please enter your phone number',
			address: 'Please enter your address',

			comment: '',
			'user_verify_token': '112233'
		},
		label: {
			car_number_only_vin: 'VIN',
			car_number_only_swiss: 'Swiss type number',
			car_number_only_kba: 'KBA number',
			car_number_vin_swiss: 'Swiss type number or VIN',
			car_number_vin_kba: 'KBA number or VIN',
			vin: 'VIN',
			swiss: 'Swiss type number',
			licence_plate: 'Licence plate',
			make: 'Car make:',
			range: 'Model:',
			type: 'Type:',
			gear: 'Gearbox:',
			first_registration: 'Vehicle production year',
			qual_md: 'Car features:',
			qual_lt: 'Type of structure:',
			mileage: 'Mileage:',
			color: 'Color',

			first_name: 'First name',
			last_name: 'Last name',
			email: 'Email',
			phone: 'Phone number',
			address: 'Address (optional)',

			comment: 'Comment:',

			user_verify_token: 'Verification token',

			privacy_agb: `I agree with <a href="{privacy_link}" class="link-none-color underline" target="_blank">privacy policy</a> and 
                                             <a href="{agb_link}" class="link-none-color underline" target="_blank">terms and conditions</a>.`,
		},
		button: {
			back: 'Back',
			next: 'Next',
			submit_car_number: 'Search',
		}
	},

	home: {
		or: 'or',
		vehicle: {
			info_vin_swiss: 'The information can be found on the vehicle card under point 23/24',
			goto_vin_swiss_link: 'Use swiss type number or VIN',
			goto_manual_car_link: 'or select your car',
			not_found_vin_swiss: 'Unfortunately, we cannot find your vehicle. Please check the entered data, or use the manual car selector.',
		},
		licence_plate: {
			no_vehicle: 'We couldn’t find your car. Please fill these information manually.',
			multiple_vehicle: 'Please select the color and make of your car.',

			manual: 'Don\'t know your plate number?',
			enter: 'Enter your licence plate:',
			found_vehicle: 'We found your car:',
			provide_half_vehicle: 'Please provide additional info.',
			provide_full_vehicle: 'We need more information to identify your vehicle.',
			tooltip: 'The vehicle identification is carried out with the help of the following source: Federal Roads Office' +
				' FEDRO. (Data version {monthYear})',
			source_provider: 'Source: Federal Roads Office (FEDRO) - Astra.',
		}
	},
	services: {
		official: {
			title: 'Service according to {make} maintenance plan',
			sub_title: 'Service for {make} {range} / {mileage}km:',
			not_found: `<p>For the selected vehicle we cannot make a recommendation for the service. <br> Select the services or works bellow, or contact us over the phone for personal consultation.</p>`,
			not_found_young: `<p>For the selected vehicle we cannot make a recommendation for the service. <br> Select the services or works bellow, or contact us over the phone for personal consultation.</p>`
		},
		labour: {
			title: 'Select services/works individually:',
			title_if_preselected_official: 'Select more services/works individually:',
			not_exist: 'Selected work is not available. For your vehicle we can make a personal offer.',
			not_found_term: 'Search term not found. Please check or try another service/work.',
			searching: 'Searching...',
			already_selected: 'included in service',
		}
	},
	leads: {
		save_user: {
			save: 'Next',
		},
		verify_user: {
			verify: 'Verify',
			resend_token: {
				not_received: 'Haven\'t received the token?',
				send_again: 'Click here to send again',
				update_data: 'Update your data',
				countdown: 'Send again in {time}',
			}
		}
	},
	details: {
		title: 'You have selected following works:',
		additional_services: 'Add additional services:',
		works_parts: 'Works and parts',
		included_in_service: 'Included in the service.',
		price_include_tax: 'Price incl taxes',
		services: {
			required: 'You did not select any service.',
		},
		terminpicker: {
			title: 'Select vehicle drop off date:',
			all_day: 'I am flexible',
			max_days: 'You already selected maximum of 5 days.',
			disabled_days: 'Your selected timeslot is not available.',
			required: 'Please choose at least one timeslot.',
		},
		start_price: 'Fixed price incl. tax:',
		discount_on_parts: 'Discount on parts:',
		discount_on_brakes: 'Discount on brakes:',
		discount_price: 'Total price:',
	},
	user: {
		book_now: 'Send request',
		annotation: 'After your booking, your request will be checked by our customer service representative. This can take a moment. Afterwards you will receive a personal confirmation by email or phone.',
	},
	thank_you: {
		title: 'Thank you for your request.',
		text: 'After we have processed and checked your request in the system, you will receive a confirmation by e-mail or telephone.',
		button: 'BACK TO HOMEPAGE',
	},
	page_not_found: {
		text: `We're sorry, the page you requested could not be found.`,
		button: 'BACK TO HOMEPAGE',
	},

	validation: {
		custom: {
			make_id: {required: 'Please select the car make.'},
			range_id: {required: 'Please select the model.'},
			type_id: {required: 'Please select the type.'},
			first_registration: {required: 'Please pick a vehicle production year'},
			gear_id: {required: 'Please select the gearbox.'},
			qual_md: {required: 'Please select the car feature.'},
			qual_lt: {required: 'Please select the type of structure.'},
			licencePlate: {
				required: 'Please enter the licence plate.',
				min: 'Licence plate required minimum 3 chars.',
				max: 'Licence plate is limited to 8 chars.'
			},
			mileage: {
				required: 'Please enter the current mileage.',
				max: 'Please check the mileage.'
			},
			zip: {
				required: 'Please enter the ZIP code.',
				min: 'Please enter the ZIP code.',
				max: 'Please check ZIP code.'
			},

			year: {required: 'Please enter the first registration date.'},
			month: {required: 'Please enter the first registration date.'},


			first_name: {required: 'First name is required.'},
			last_name: {required: 'Last name is required.'},
			phone: {required: 'Phone number is required.'},
			email: {required: 'Email is required.'},
			address: {required: 'Address is required.'},

			privacy_agb: {required: 'Please accept the terms and conditions and privacy policy.'},
		}
	},

	preparedServices: {
		oil: {
			name: null,
			second_name: null
		},
		zahriemen: {
			name: 'timing belt',
			second_name: 'check'
		},

		break_fluid: {
			name: null,
			second_name: null
		},
		airfilter: {
			name: null,
			second_name: null
		},
		klimafilter: {
			name: null,
			second_name: null
		},
	}
};