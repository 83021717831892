export const getters = {
	globalLicencePlate: state => {
		return state.licence_plate.data;
	},
	showByDefaultLP: state => {
		return state.licence_plate.show_by_default
	},
	showByUserLP: state => {
		return state.licence_plate.show_by_user
	},
	multipleVehicleLP: state => {
		return state.licence_plate.multiple_vehicle
	},
	statusLP: state => {
		return state.licence_plate.status
	},
	existLPCar: state => {
		return state.licence_plate.exists;
	},
	attemptsLP: state => {
		return state.licence_plate.attempts;
	},
	canShowLP: state => {
		return state.licence_plate.attempts > 0 && state.licence_plate.show_by_default === true;
	}
};