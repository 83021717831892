<template>
  <div class="form-group">
    <div @click="toggle(item)" :class="{disabled: !item.exist}">

      <label :for="'sservice' + item.id">
        {{item.name}}
      </label>
      <v-checkbox :loading="loading" :checked="item.selected" :disabled="!item.exist || item.already_selected"
                  :id="'sservice' + item.id" :exist="item.exist"></v-checkbox>

      <p v-if="!item.exist"><i class="fa fa-exclamation-triangle"></i> {{$t('services.labour.not_exist')}} </p>
    </div>
    <div v-if="item.selected && item.siblings.length" class="service-siblings">
      <ul>
        <li v-for="(item, index) in item.siblings" :key="index" v-if="item.exist">{{item.name}}</li>
      </ul>
    </div>
    <div v-if="item.already_selected" class="already-selected"><i class="fa fa-info-circle"></i>
      {{$t('services.labour.already_selected')}}
    </div>
  </div>
</template>
<script>
	import VCheckbox from '@/components/forms/inputs/general/VCheckbox';

	export default {
		components: {VCheckbox},

		props: {
			item: Object
		},
		data: function() {
			return {
				loading: false,
			}
		},
		methods: {
			toggle(item) {
				if (item.exist && !item.already_selected) {
					if (!this.loading) {
						this.loading = true;
            this.$emit('onLoading', {...item, loading: this.loading});
						this.$store.dispatch('checkLabourSearch', [item]).finally(() => {
							this.loading = false;
              this.$emit('onLoading', {...item, loading: this.loading});
						})
					}
				}
			}
		}
	}
</script>