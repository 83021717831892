<template>
  <div class="container">
    <div class="row">
      <div class="form-widget-content">
        <div class="col-md-6 widget-container">
          <widget></widget>
        </div>
        <div class="col-md-6">
          <steps></steps>
          <div class="thanks-form">
            <h3 v-html="$t('thank_you.title')"></h3>
            <p class="text-center" v-html="$t('thank_you.text')"></p>
            <button @click="goToHome" v-html="$t('thank_you.button')"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
	import Widget from '@/components/layout/Widget';
	import Steps from '@/components/layout/Steps';
	import store from '../store/store';
	import {lang} from '@/lang';
  import SessionStorage from "../helpers/SessionStorage";

	export default {
		page: {
			title: lang[store.getters.language].title.thank_you,
		},
		components: {Widget, Steps},
		created() {
			SessionStorage.removeItem('offer_token');
      this.$store.dispatch('resetState');
    },
		computed: {
			web() {
				return window._repair_shop.web;
			}
		},
		methods: {
			goToHome() {
				window.location.replace(this.web);
				// this.$router.push({name: 'home'});
			}
		}
	}
</script>