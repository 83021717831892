<template>
    <footer>
        <div class="container">
            <div class="copyright">
                <ul>
                    <li>© {{new Date().getFullYear()}} {{name}}</li>
                    <li><a target="_blank" href="/datenschutzerklarung">{{$t('footer.privacy')}}</a></li>
                    <li><a target="_blank" href="/agb">{{$t('footer.agb')}}</a></li>
                </ul>
            </div>
            <address>
                <ul>
                    <li v-html="address"/>
                    <li v-if="email"><a :href="'mailto:' + email"><i class="fa fa-envelope"></i> {{email}}</a></li>
                    <li><a :href="'tel:' + phone"><i class="fa fa-phone"></i> {{phone}}</a></li>
                    <li v-if="web"><a :href="webWithPrefix" target="_blank"><i class="fa fa-globe"></i> {{webShort}}</a></li>
                </ul>
            </address>
        </div>
    </footer>
</template>
<script>
    export default {
        computed: {
            name() {
                return window._repair_shop.name;
            },
            address() {
                return `${window._repair_shop.address}, ${window._repair_shop.zip} ${window._repair_shop.city}`;
            },
            email() {
                return window._repair_shop.email;
            },
            phone() {
                return window._repair_shop.phone;
            },
            web() {
              return window._repair_shop.web;
            },
            webShort() {
              return window._repair_shop.web.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
            },
            webWithPrefix() {
              let url = window._repair_shop.web
              if (!/^https?:\/\//i.test(url)) {
                return url = 'http://' + url;
              }
              return url
            }
        }
    }
</script>