import moment from "moment";

/**
 * Countdown
 * @param minutes
 * @param seconds
 * @param update
 * @param done
 */
const countdown = (minutes, seconds, update, done) => {
	let duration = moment.duration({
		'minutes': minutes,
		'seconds': seconds
	});

	let timestamp = new Date(0, 0, 0, 2, 10, 30);
	let interval = 1;
	let timer = setInterval(() => {
		timestamp = new Date(timestamp.getTime() + interval * 1000);

		duration = moment.duration(duration.asSeconds() - interval, 'seconds');
		let min = duration.minutes();
		let sec = duration.seconds();

		sec -= 1;
		if (min < 0) {
			clearInterval(timer)
			done()
			return;
		}
		if (min < 10 && min.length != 2) min = '0' + min;
		if (sec < 0 && min != 0) {
			min -= 1;
			sec = 59;
		} else if (sec < 10 && sec.length != 2) sec = '0' + sec;

		update(min + ':' + sec);
		if (min == 0 && sec == 0){
			clearInterval(timer);
			done()
		}
	}, 1000);
};

export default countdown;