let carFactory = () => {
    return {
        licence_plate: '',
        car_number: '',
        car_number_type: '',
        make_id: '',
        first_registration: '',
        range_id: '',
        type_id: '',
        gear_id: '',
        qual_md: '',
        qual_lt: '',
        mileage: '',
        makes: [],
        first_registration_list: [],
        ranges: [],
        types: [],
        gears: [],
        qual_md_list: [],
        qual_lt_list: [],
        colors: [],
        color_id: '',
        token: '',
    }
};

export default carFactory;