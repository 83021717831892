import _ from 'lodash';

export const mutations = {
    waiting: (state, payload) => {
        state.services.waiting = payload;
    },
    setOfficial: (state, payload) => {
        state.services.official.prev = payload.prev;
        state.services.official.next = payload.next;
        state.services.official.show = payload.serviceSelected;
    },

    setLabourSearch: (state, payload) => {
        state.services.labour.search = payload;
    },

    appendLabourSearch: (state, payload) => {
        if(!state.services.labour.search.find(labour => labour.kor_id === payload.kor_id && labour.item_mp_id === payload.item_mp_id)){
            state.services.labour.search.push(payload);
        }
    },

    setLabourCustom: (state, payload) => {
        state.services.labour.custom = payload;
    },

    toggleOfficial: (state, payload) => {
        let service = {};
        if (state.services.official.show === 0) {
            service = state.services.official.prev.find(item => item.ItemMpId === payload.ItemMpId);
            service.selected = !service.selected;
        } else {
            service = state.services.official.next.find(item => item.ItemMpId === payload.ItemMpId);
            service.selected = !service.selected;
        }
    },

    toggleAllOfficial: (state) => {
        if (state.services.official.show === 0) {
            for (let item of state.services.official.prev) {
                item.selected = !item.selected;
            }
        } else {
            for (let item of state.services.official.next) {
                item.selected = !item.selected;
            }
        }
    },

    toggleLabourCustom: (state, payload) => {
        let service = state.services.labour.custom.find(item => item.id === payload.id);
        service.selected = !service.selected;
    },

    toggleMultipleLabourCustom: (state, payload) => {
        for (let id of payload) {
            let item = state.services.official.next.find(item => item.id === id);
            item.selected = !item.selected;
        }
    },

    toggleLabourSearch: (state, payload) => {
        let service = state.services.labour.search[payload.id];
        service.selected = !service.selected;
    },

    term: (state, payload) => {
        state.search.term = payload;
        state.search.temp_search = [];

        state.services.labour.search.forEach(item => {
            let name = item.name.toLocaleLowerCase(),
                searchTerm = state.search.term.toLocaleLowerCase();
            if (name.includes(searchTerm)) {
                state.search.temp_search.push(item);
            }
        });
    },

    appendTempSearch: (state, payload) => {
        payload.forEach(item => {
            let exist = state.search.temp_search.find(labour => {
                return labour.kor_id === item.kor_id && labour.item_mp_id === item.item_mp_id;
            });

            if(!exist){
                let searchLabour = state.services.labour.search.find(labour => {
                    return labour.kor_id === item.kor_id && labour.item_mp_id === item.item_mp_id;
                });
                state.search.temp_search.push(searchLabour ? searchLabour : item)
            }
        })
    },

    //TODO ovo refaktorisati kada se refaktorise ovo ispod
    unsetLabourSearchService: (state, payload) => {
        state.services.labour.search.forEach(item => {
            if (item.id === payload.service.id) {
                item.selected = false;
                item.siblings.forEach(sibling => {
                    sibling.selected = false;
                    sibling.childs.forEach(child => {
                        child.serviceGroupChild.forEach(subChild => {
                            subChild.selected = false;
                        })
                    });
                });
                item.childs.forEach(child => {
                    child.serviceGroupChild.forEach(subChild => {
                        subChild.selected = false;
                    })
                });
            }
        })
    },

    setServicesAndPrices: (state, payload) => {
        state.services.official.prev = payload.prev;
        state.services.official.next = payload.next;
        state.services.labour.custom = payload.custom;
        state.services.labour.search = payload.search;
    },


    //TODO ovo refaktorisati i prebaiti na backend!
    setLabourSearchService: (state, payload) => {
        let item = payload.item,
            result = payload.data.service,
            replacementService = payload.data.replace_service,
            service = {};

        for (let sitem of state.services.labour.search) {
            if (sitem.item_mp_id === item.item_mp_id) service = sitem;
        }

        if (Object.keys(replacementService).length > 0) {
            service.name = replacementService.name;
            service.childs = replacementService.childs;
            service.counter = replacementService.counter;
            service.created_at = replacementService.created_at;
            service.description = replacementService.description;
            service.display_description = replacementService.display_description;
            service.exist = replacementService.exist;
            service.id = replacementService.id;
            service.is_master = replacementService.is_master;
            service.item_mp_id = replacementService.item_mp_id;
            service.kor_id = replacementService.kor_id;
            service.labour_time_category_id = replacementService.labour_time_category_id;
            service.order = replacementService.order;
            service.selected = replacementService.selected;
            service.showDescription = replacementService.showDescription;
            service.siblings = replacementService.siblings;
            service.updated_at = replacementService.updated_at;
            service.visible = replacementService.visible;
            service.workId = replacementService.workId;
            service.replaced_with_id = replacementService.replaced_with_id;
        }

        let countServices = result.length;
        state.existPartsInSearch = true;

        result.forEach((el, index) => {
            if (el.works.length === 0) {
                if (index === 0) service.exist = false;
                else service.siblings[index - 1].exist = false;
            } else {
                if (el.works.length === 1) {
                    if (el.works[0].item_mp_id > 10000000) {
                        service.selected = true;
                    } else if (el.works[0].work_id) {
                        if (el.works[0].parts.length === 0) { // Custom servis, nema partove
                            service.workId = el.works[0].work_id;
                            service.selected = true
                        } else { // api servis
                            el.works[0].parts.forEach(part => {
                                if (part.serviceGroupChild.length === 1) { //ima partove po jedan element
                                    if (service.item_mp_id === el.works[0].item_mp_id) {
                                        service.workId = el.works[0].work_id;
                                        service.childs = el.works[0].parts;
                                        service.selected = countServices >= 1;
                                    } else {
                                        service.siblings.forEach(sibling => {
                                            if (sibling.item_mp_id === el.works[0].item_mp_id) {
                                                sibling.workId = el.works[0].work_id;
                                                sibling.childs = el.works[0].parts;
                                                sibling.selected = countServices >= 1;
                                            }
                                        })
                                    }
                                } else {
                                    // console.log(el);
                                }
                            });
                        }
                    }
                } else {
                    // console.log(el);
                }
            }

        });

        if (!service.exist) {
            service.childs = [];
            service.siblings.forEach(sibling => {
                sibling.selected = false;
                sibling.childs = [];
            })
        }

        if(service.selected === false && service.from_tec === true){
            _.remove(state.services.labour.search, function (labour) {
                return labour.kor_id === service.kor_id && labour.item_mp_id === service.item_mp_id;
            })
        }
    },
};
