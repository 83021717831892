import de from './de/lang';
import en from './en/lang';
import fr from './fr/lang';


export const lang = {
    de,
    en,
    fr,
};
export const validation = {
    de: de.validation,
    en: en.validation,
    fr: fr.validation,
};