let user = () => {
    return {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        comment: '',
    }
};

export default user;