<template>
  <div class="container">
    <div class="row">
      <div class="form-widget-content">
        <div class="col-md-6 widget-container">
          <widget></widget>
        </div>
        <div class="col-md-6">
          <steps></steps>
          <user-form @submit="submit" @error="error"></user-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
	import UserForm from '@/components/forms/User';
	import Widget from '@/components/layout/Widget';
	import Steps from '@/components/layout/Steps';
	import store from '../store/store';
	import {lang} from '../lang';

	export default {
		page: {
			title: lang[store.getters.language].title.user,
		},
		components: {UserForm, Widget, Steps},
		created() {
			this.$store.dispatch('setStep', 3);
		},
		methods: {
			submit() {
				this.$router.push({name: 'thanks'});
			},
      error(error) {
        this.$toastr("error", error.response.data.message, this.$t("toastr.error"));

        if(error.response.status === 424){
          this.$router.push({name: 'home'});
          this.$store.dispatch('resetState');
        }
      }
		}
	}
</script>