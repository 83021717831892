import address from '@/factory/address';

const resetAddress = store => {
    store.subscribe((mutation, state) => {
        let type = mutation.type;
        if (type === 'resetAddress') {
            Object.keys(state.address.address).forEach(key => {
                if (isAllowedForChange(key)) state.address.address[key] = address()[key]
            });
        }
    })
};
const isAllowedForChange = (key) => {
    return ['zip'].includes(key) === false;
};

export default resetAddress;