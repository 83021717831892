export const getters = {
    first_name: state => state.user.first_name,
    last_name: state => state.user.last_name,
    email: state => state.user.email,
    phone: state => state.user.phone,
    address: state => state.user.address,
    comment: state => state.user.comment,
    routeUserExist: (state, getters) => {
        return (getters.first_name !== '' && getters.last_name !== '' && getters.email !== '' && getters.phone !== '');
    }
};