<template>
  <div class="vehicle-form">
    <p v-html="$t('pages.vehicle_title')"></p>

    <div class="vehicle-form-box">
      <div class="clearfix"></div>
      <div v-if="loadingForm" class="loading">
            <span>
              <i class="fa fa-spinner"></i>
            </span>
      </div>
      <form v-if="settingShowVin || settingShowSwissNr || settingShowKba" @submit.prevent="setCarNumber"
            class="car-number">
        <div class="form-group has-feedback" :class="hasError('tempCarNumber')">

          <div class="input-btn-group">
            <button type="button" class="fbutton" tabindex="0" v-if="showBtnVinSwiss"
                    @click.stop="setCarNumber">
              {{ $t('form.button.submit_car_number') }}
            </button>
            <label for="tempCarNumber">
              {{ labelVinSwiss() }}
              <span class="icon" v-tooltip="{content: getTooltip, trigger : 'hover click'}"><i
                  class="fa fa-info-circle"></i></span>
            </label>
            <input type="text" name="tempCarNumber" id="tempCarNumber" v-model="tempCarNumber"
                   ref="tempCarNumber"
                   class="form-control"
                   @focus="toggleBtnVinSwiss(true)"
                   @blur="hideBtnVinSwiss"
                   :placeholder="showBtnVinSwiss === false ? placeholderVinSwiss() : ''">
            <!--@keyup.enter="setCarNumber"-->
          </div>
          <div v-if="hasError('tempCarNumber')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{ errors.first('tempCarNumber') }}</small>
          </div>
          <div class="text-center">
            <small>{{ $t('home.or') }}</small>
          </div>
        </div>
      </form>
      <form @submit.prevent="submit">
        <div v-if="isMakesVisible" class="form-group has-feedback"
             :class="hasError('make_id')"
             @click="toggleSelect('make_id', !opened.make_id)">
          <label for="make_id" v-html="$t('form.label.make')"></label>

          <v-select name="make_id" :list="makes" id="make_id" :value="make_id"
                    :opened="opened.make_id" :type="MAKE_ID" :disabled="!makes.length"
                    :placeholder="$t('form.placeholder.make')" v-validate="'required'" v-model="make_id"
                    @toggle="toggleSelect('make_id', false)" @change="getData"/>

          <div v-if="hasError('make_id')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{ errors.first('make_id') }}</small>
          </div>
        </div>

        <div v-if="car_number == '' && first_registration !== true" class="form-group has-feedback" :class="hasError('first_registration')"
             @click="toggleSelect('first_registration', !opened.first_registration)">
          <label for="first_registration" v-html="$t('form.label.first_registration')"></label>

          <v-select name="first_registration" :list="first_registration_list" id="first_registration"
                    :value="first_registration"
                    :opened="opened.first_registration" :type="FIRST_REGISTRATION"
                    :disabled="!first_registration_list.length"
                    :placeholder="$t('form.placeholder.first_registration')"
                    @toggle="toggleSelect('first_registration', false)"
                    @change="getData" v-validate="'required'" v-model="first_registration"/>

          <div v-if="hasError('first_registration')">
            <span class="form-control-feedback"></span>
            <small class="help-block" v-html="errors.first('first_registration')"></small>
          </div>
        </div>

        <div class="form-group has-feedback" :class="hasError('range_id')"
             @click="toggleSelect('range_id', !opened.range_id)">
          <label for="range_id" v-html="$t('form.label.range')"></label>

          <v-select name="range_id" :list="ranges" id="range_id" :value="range_id"
                    :opened="opened.range_id" :type="RANGE_ID" :disabled="!ranges.length"
                    :placeholder="$t('form.placeholder.range')" @toggle="toggleSelect('range_id', false)"
                    @change="getData" v-validate="'required'" v-model="range_id"/>
          <div v-if="hasError('range_id')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{ errors.first('range_id') }}</small>
          </div>
        </div>


        <div v-if="type_id !== true" class="form-group has-feedback" :class="hasError('type_id')"
             @click="toggleSelect('type_id', !opened.type_id)">
          <label for="type_id" v-html="$t('form.label.type')"></label>

          <v-select name="type_id" :list="types" id="type_id" :value="type_id"
                    :opened="opened.type_id" :type="TYPE_ID" :disabled="!types.length"
                    :placeholder="$t('form.placeholder.type')" @toggle="toggleSelect('type_id', false)"
                    @change="getData" v-validate="'required'" v-model="type_id"/>
          <div v-if="hasError('type_id')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{ errors.first('type_id') }}</small>
          </div>
        </div>

        <div v-if="car_number && first_registration !== true" class="form-group has-feedback" :class="hasError('first_registration')"
             @click="toggleSelect('first_registration', !opened.first_registration)">
          <label for="first_registration" v-html="$t('form.label.first_registration')"></label>

          <v-select name="first_registration" :list="first_registration_list" id="first_registration"
                    :value="first_registration"
                    :opened="opened.first_registration" :type="FIRST_REGISTRATION"
                    :disabled="!first_registration_list.length"
                    :placeholder="$t('form.placeholder.first_registration')"
                    @toggle="toggleSelect('first_registration', false)"
                    @change="getData" v-validate="'required'" v-model="first_registration"/>

          <div v-if="hasError('first_registration')">
            <span class="form-control-feedback"></span>
            <small class="help-block" v-html="errors.first('first_registration')"></small>
          </div>
        </div>

        <div v-if="gears.length > 1" class="form-group has-feedback"
             :class="hasError('gear_id')" @click="toggleSelect('gear_id', !opened.gear_id)">
          <label for="gear_id" v-html="$t('form.label.gear')"></label>

          <v-select name="gear_id" :list="gears" id="gear_id" :value="gear_id"
                    :opened="opened.gear_id" :type="GEAR_ID" :disabled="!gears.length"
                    :placeholder="$t('form.placeholder.gear')" @toggle="toggleSelect('gear_id', false)"
                    @change="getData" v-validate="'required'" v-model="gear_id"/>
          <div v-if="hasError('gear_id')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{ errors.first('gear_id') }}</small>
          </div>
        </div>

        <div v-if="qual_md_list.length > 1" class="form-group has-feedback"
             :class="hasError('qual_md')" @click="toggleSelect('qual_md', !opened.qual_md)">
          <label for="qual_md" v-html="$t('form.label.qual_md')"></label>

          <v-select name="qual_md" :list="qual_md_list" id="qual_md" :value="qual_md"
                    :opened="opened.qual_md" :type="QUAL_MD" :disabled="!qual_md_list.length"
                    :placeholder="$t('form.placeholder.qual_md')" v-validate="'required'" v-model="qual_md"
                    @toggle="toggleSelect('qual_md', false)" @change="getData"/>

          <div v-if="hasError('qual_md')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{ errors.first('qual_md') }}</small>
          </div>
        </div>

        <div v-if="qual_lt_list.length > 1" class="form-group has-feedback"
             :class="hasError('qual_lt')" @click="toggleSelect('qual_lt', !opened.qual_lt)">
          <label for="qual_lt" v-html="$t('form.label.qual_lt')"></label>

          <v-select name="qual_lt" :list="qual_lt_list" id="qual_lt" :value="qual_lt"
                    :opened="opened.qual_lt" :type="QUAL_LT" :disabled="!qual_lt_list.length"
                    :placeholder="$t('form.placeholder.qual_lt')" v-validate="'required'" v-model="qual_lt"
                    @toggle="toggleSelect('qual_lt', false)" @change="getData"/>

          <div v-if="hasError('qual_lt')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{ errors.first('qual_lt') }}</small>
          </div>
        </div>

        <div class="form-group has-feedback" :class="hasError('mileage')">
          <label for="mileage" v-html="$t('form.label.mileage')"></label>
          <input type="number" name="mileage" id="mileage" v-model="mileage"
                 @keypress="DDE + IML($event, 6, mileage)"
                 v-validate="{required:true, max:6, min:0, regex:'^[0-9]+'}"
                 class="form-control" min="0"
                 :placeholder="$t('form.placeholder.mileage')" autocomplete="off">
          <div v-if="hasError('mileage')">
            <span class="form-control-feedback"></span>
            <small class="help-block"> {{ errors.first('mileage') }}</small>
          </div>
        </div>
      </form>
    </div>

    <div class="form-group">
      <a v-if="canGoBackToLP" href="javascript:void(0)" @click.stop="backToLP" class="back-link">
        <i class="fa fa-angle-left"></i>
        {{ $t('form.button.back') }}
      </a>
      <button @click.stop="submit" class="pull-r" :class="{disabled:!validateIsCarSelected}">
        {{ $t('form.button.next') }}
      </button>
    </div>
  </div>
</template>
<script>
import InputHasError from "@/mixins/InputHasError";
import InputMaxLength from "@/mixins/InputMaxLength";
import DisableDotExponential from "@/mixins/DisableDotExponential";
import DatePicker from "@/components/forms/inputs/general/Datepicker";
import Vehicle from "@/mixins/components-di/Vehicle";
import VSelect from "@/components/forms/inputs/general/VSelect";
import {RepairShopNotServicingCar} from '../../exceptions/RepairShopNotServicingCar';
import {CarDataNotExisting} from '../../exceptions/CarDataNotExisting';
import SessionStorage from "../../helpers/SessionStorage";

export default {
  mixins: [Vehicle, InputHasError, InputMaxLength, DisableDotExponential],
  components: {DatePicker, VSelect},
  computed: {
    showByUserLP: {
      get() {
        return this.$store.getters.showByUserLP;
      }
    },
    canGoBackToLP: {
      get() {
        return this.$store.getters.canShowLP;
      }
    },
    loadingForm: {
      get() {
        return this.$store.getters.loadingForm;
      }
    },
    isMakesVisible: {
      get() {
        return window._settings.is_makes_visible;
      }
    },
    validateIsCarSelected: {
      get() {
        return this.$store.getters.validateIsCarSelected;
      }
    }
  },
  data() {
    return {
      manual: true,
      showBtnVinSwiss: false,
      tempCarNumber: '',
    }
  },
  created: function () {
    if (this.makes.length === 0) {
      this.$store.dispatch("getCar").then(() => {
        if (this.makes.length === 1) {
          this.$store.dispatch("getCar");
        }
      });
    }

    if (this.makes.length > 0 && !SessionStorage.getItem('offer_token')) {
      this.$store.commit('resetCar');
      this.getData();
    }
  },

  updated: function () {
    if (this.types.length === 0 && this.type_id == null && this.range_id) {
      this.$store.dispatch("getCar");
    }
  },

  methods: {
    getData() {
      return this.$store.dispatch("getCar").catch(error => {
        if (error instanceof RepairShopNotServicingCar) {
          this.$toastr("error", error.message, this.$t("toastr.error"));

          this.$store.commit('resetCar');
          this.$store.dispatch("getCar").then(() => {
            // this.setManual();
            // this.$emit('submit');
          });
        }

        if (error instanceof CarDataNotExisting && this.car_number !== '') {
          let message = this.$t('home.vehicle.not_found_vin_swiss'),
              errorTitle = this.$t("toastr.error");

          this.$toastr("error", message, errorTitle);
          this.$store.commit('resetCar');
          this.getData();
        }

      });
    },
    toggleBtnVinSwiss(value) {
      this.showBtnVinSwiss = value;
    },
    hideBtnVinSwiss() {
      setTimeout(() => {
        this.toggleBtnVinSwiss(false);
      }, 150)
    },
    setCarNumber() {
      this.$store.commit('resetCar');
      this.$refs.tempCarNumber.blur();
      this.car_number = this.tempCarNumber;
      this.getData().then(() => {
        this.$validator.validateAll();
      });
    },
    backToLP() {
      this.$store.commit('setShowByUserLP', true);
    },
    submit() {
      this.$validator.validateAll();
      for (let item in this.fields) {
        if (!this.fields[item].valid) {
          this.$scrollTo(`#${item}`);
        }
      }


      if (this.validateIsCarSelected) {
        this.$validator.validateAll().then(data => {
          if (data) {
            this.$store.dispatch("getCar").then(() => {
              this.$store.dispatch("getServices").then(() => {
                this.$emit("submit");
              });
            }).catch(() => {
              this.$store.dispatch("getServices").then(() => {
                this.$emit("submit");
              });
            });
          }
        });
      }
    },
  }
};
</script>
