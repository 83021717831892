<template>
    <div class="form-group">
        <label :for="'rservice' + item.ItemMpId">
            <i class="fa fa-check"></i>
            <span class="name"> {{item.ItemMpText}}</span>
            <span v-if="hasTooltip(item)" class="icon" v-tooltip="{content: getTooltip(item), trigger : 'hover click'}"><i
                    class="fa fa-info-circle"></i></span>
        </label>
    </div>
</template>
<script>
    export default {
        props: {
            item: Object
        },
        data: function () {
            return {}
        },
        methods: {
            toggle(item) {
                this.$store.commit('toggleOfficial', item);
            },
            hasTooltip(item) {
                return (item.childs.length && item.childs.length > 1) || (item.alternative && item.alternative.description);
            },
            getTooltip(item) {
                if (item.alternative && item.alternative.description) {
                    return item.alternative.description;
                } else {
                    let content = `<ul>`;
                    item.childs.forEach(child => {
                        content += `<li>${child.serviceGroupName}</li>`;
                    });
                    content += `</ul>`;
                    return content;
                }
            }
        }
    }
</script>