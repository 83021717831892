<template>
    <div>
        <div>
            <form action="">
                <div class="form-group has-feedback" :class="hasError('first_name')">
                    <label for="first_name" v-html="$t('form.label.first_name')"></label>
                    <input type="text" name="first_name" id="first_name" v-model="first_name"
                           v-validate="{required:true}"
                           class="form-control"
                           :placeholder="$t('form.placeholder.first_name')" autocomplete="off">
                    <div v-if="hasError('first_name')">
                        <span class="form-control-feedback"></span>
                        <small class="help-block"> {{ errors.first('first_name') }}</small>
                    </div>
                </div>
                <div class="form-group has-feedback" :class="hasError('last_name')">
                    <label for="last_name" v-html="$t('form.label.last_name')"></label>
                    <input type="text" name="last_name" id="last_name" v-model="last_name"
                           v-validate="{required:true}"
                           class="form-control"
                           :placeholder="$t('form.placeholder.last_name')" autocomplete="off">
                    <div v-if="hasError('last_name')">
                        <span class="form-control-feedback"></span>
                        <small class="help-block"> {{ errors.first('last_name') }}</small>
                    </div>
                </div>
                <div class="form-group has-feedback" :class="hasError('email')">
                    <label for="email" v-html="$t('form.label.email')"></label>
                    <input type="email" name="email" id="email" v-model="email"
                           v-validate="{required:true, email:true}"
                           class="form-control"
                           :placeholder="$t('form.placeholder.email')" autocomplete="off">
                    <div v-if="hasError('email')">
                        <span class="form-control-feedback"></span>
                        <small class="help-block"> {{ errors.first('email') }}</small>
                    </div>
                </div>
                <div class="form-group has-feedback" :class="hasError('phone')">
                    <label for="phone" v-html="$t('form.label.phone')"></label>
                    <vue-tel-input v-model="phone" inputId="phone" name="phone"
                                   :validCharactersOnly="true"
                                   v-validate="{required:true}"
                                   :defaultCountry="phoneCodes[0]"
                                   @validate="validatePhone"
                                   @input="phoneInput"
                                   mode="international"
                                   :onlyCountries="phoneCodes"
                                   :dynamicPlaceholder="true"/>
                    <div v-if="hasError('phone')">
                        <span class="form-control-feedback"></span>
                        <small class="help-block"> {{ errors.first('phone') }}</small>
                    </div>
                </div>
                <div class="form-group has-feedback" :class="hasError('address')">
                    <label for="address" v-html="$t('form.label.address')"></label>
                    <input type="text" name="address" id="address" v-model="address"
                           class="form-control"
                           :placeholder="$t('form.placeholder.address')" autocomplete="off">
                    <div v-if="hasError('address')">
                        <span class="form-control-feedback"></span>
                        <small class="help-block"> {{ errors.first('address') }}</small>
                    </div>
                </div>
            </form>
        </div>
        <div class="form-group text-center">
            <button @click.prevent="submit" type="submit" v-bind:class="{ 'disabled': loading }"
                    v-html="$t('leads.save_user.save')" :disabled="loading"></button>
        </div>
    </div>
</template>
<script>
import User from '@/mixins/components-di/User';
import InputHasError from "@/mixins/InputHasError";
import InputMaxLength from "@/mixins/InputMaxLength";
import DisableDotExponential from "@/mixins/DisableDotExponential";
import {VueTelInput} from 'vue-tel-input'
import PhoneCodes from '@/helpers/PhoneCodes';

export default {
    components: {VueTelInput},

    mixins: [User, InputHasError, InputMaxLength, DisableDotExponential],
    data() {
        return {
            loading: false,
            phoneCodes: PhoneCodes()
        }
    },
    methods: {
        validatePhone(ar) {
            if(ar.number.input){
                this.$validator.validate('phone')
            }
        },
        phoneInput(value, object) {
            if(value === object.country.dialCode){
                this.phone = '+' + object.country.dialCode;
            }
        },
        submit() {
            this.$validator.validateAll().then(data => {
                if (data) {
                    this.loading = true;

                    this.$store.dispatch('saveUser')
                        .then(() => {
                            this.$emit('submit');
                        })
                        .catch(error => {
                            this.$emit('error', error);
                        })
                        .finally(() => {
                            this.loading = false
                        });

                }
            });
        }
    }
}
</script>