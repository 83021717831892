<template>
    <div class="steps">
        <ul>
            <li :class="{active: step >= 1, current: step >= 0}">
                <span>1</span>
            </li>
            <li :class="{active: step >= 2, current: step >= 1}">
                <span>2</span>
            </li>
            <li :class="{active: step >= 3, current: step >= 2}">
                <span>3</span>
            </li>
            <li :class="{active: step >= 4, current: step >= 3}">
                <span>4</span>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        computed: {
            step: {
                get() {
                    return this.$store.getters.step;
                }
            }
        },
    }
</script>