<template>
  <div class="search-services">
    <div class="form-group">
      <h3 v-html="title"></h3>
      <input type="text" v-model="term" name="term" id="term" class="form-control" v-on:input="serviceSearch"
             :placeholder="$t('form.placeholder.search_term')" autocomplete="off">
    </div>

    <p v-if="!list.length && !serviceSearching" v-html="$t('services.labour.not_found_term')"></p>
    <s-checkbox v-else v-for="(item, index) in list" :key="index" :item="item" @onLoading="onLoading"></s-checkbox>

    <p v-if="serviceSearching" v-html="$t('services.labour.searching')"/>
  </div>
</template>
<script>
	import SearchCheckbox from '@/components/forms/inputs/services/SearchCheckbox';
  import _ from 'lodash';

	export default {
		props: {
			list: Array,
			preselectedOfficial: [Boolean, Number],
		},
		components: {'s-checkbox': SearchCheckbox},
    data: function () {
		  return {
        servicesLoading: [],
        serviceSearching: false,
      }
    },
		computed: {
			term: {
				get() {
					return this.$store.getters.term;
				},
				set(val) {
					this.$store.commit("term", val);
				}
			},
			title() {
				if (this.preselectedOfficial) {
					return this.$t('services.labour.title_if_preselected_official');
				}

				return this.$t('services.labour.title');
			}
		},
    methods: {
		  serviceSearch(e) {
		    if(e.target.value.length > 2){
          this.serviceSearching = true;

          this.serviceSearchDebounced();
        }
      },

      serviceSearchDebounced: _.debounce(function () {
        this.$store.dispatch('searchService')
            .catch(error => {
              this.$emit('error', error);
            })
            .finally(() => this.serviceSearching = false);
      }, 500),

      onLoading(service) {
        let serviceId = service.hasOwnProperty('replaced_with_id') && service.id !== service.replaced_with_id ? service.replaced_with_id : service.id
        if(this.servicesLoading.find(serviceLoading => serviceLoading.id === serviceId)){
          this.servicesLoading = this.servicesLoading.map(serviceLoading => {
            return serviceLoading.id === serviceId ? {...serviceLoading, loading: service.loading} : serviceLoading;
          })
        }
        else {
          this.servicesLoading.push(service);
        }

        this.$emit('onServiceLoading', !!this.servicesLoading.filter(service => service.loading === true).length);
      }
    },
	}
</script>