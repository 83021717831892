export const getters = {
    services: state => state.services,
    prevServices: state => state.services.official.prev,
    nextServices: state => state.services.official.next,
    labourSearchServices: state => state.services.labour.search,
    labourCustomServices: state => state.services.labour.custom,
    waiting: state => state.services.waiting,
    term: state => state.search.term,
    tempSearch: state => state.search.temp_search,

    routeIsServiceExist: (state, getters) => {
        return (getters.officialServices.length !== 0 || getters.labourSearchServices.length !== 0 || getters.labourCustomServices.length !== 0);
    },

    officialServices: state => {
        return state.services.official.show === 0 ? state.services.official.prev : state.services.official.next;
    },

    isSelectedOfficialServices: (state, getters) => {
        let data = getters.officialServices.filter(item => item.selected === true);
        return data.length > 0;
    },

    isSelectedLabourSearch: (state, getters) => {
        let data = getters.labourSearchServices.filter(item => item.selected === true);
        return data.length > 0;
    },

    isSelectedLabourCustom: (state, getters) => {
        let data = getters.labourCustomServices.filter(item => item.selected === true);
        return data.length > 0;
    },

    findLabourServicesById: (state, getters) => (payload) => {
        let data = [];
        for (let item of getters.labourServices) {
            if (payload.includes(item.id)) data.push(item);
        }
        return data;
    },

    selectedOfficial: (state, getters) => {
        let data = [];
        for (let item of getters.officialServices) {
            if (item.selected) data.push(item);
        }
        return data;
    },

    selectedLabourSearch: (state, getters) => {
        let data = [];
        for (let item of getters.labourSearchServices) {
            if (item.selected) data.push(item);
        }
        return data;
    },
    selectedLabourCustom: (state, getters) => {
        let data = [];
        for (let item of getters.labourCustomServices) {
            if (item.selected) data.push(item);
        }
        return data;
    }

};