import moment from 'moment';


export const getters = {
	licence_plate: state => state.car.licence_plate,
	car_number: state => state.car.car_number,
	car_number_type: state => state.car.car_number_type,
	make_id: state => state.car.make_id,
	first_registration: state => state.car.first_registration,
	color_id: state => state.car.color_id,
	colors: state => state.car.colors,
	range_id: state => state.car.range_id,
	type_id: state => state.car.type_id,
	gear_id: state => state.car.gear_id,
	qual_md: state => state.car.qual_md,
	qual_lt: state => state.car.qual_lt,
	mileage: state => state.car.mileage,
	makes: state => state.car.makes,
	first_registration_list: state => state.car.first_registration_list,
	ranges: state => state.car.ranges,
	types: state => state.car.types,
	gears: state => state.car.gears,
	qual_md_list: state => state.car.qual_md_list,
	qual_lt_list: state => state.car.qual_lt_list,

	routeIsCarSelected: (state, getters) => {
		return (getters.make_id !== '' && getters.first_registration !== '' && getters.range_id !== '' && getters.type_id !== '' && getters.mileage !== '');
	},

	make: state => {
		if (state.car.make_id) {
			let make = state.car.makes.find(item => item.id === state.car.make_id);
			return make.name;
		}
		return '';
	},
	range: state => {
		if (state.car.range_id) {
			let range = state.car.ranges.find(item => item.id === state.car.range_id);
			return range.name;
		}
		return '';
	},

	type: state => {
		if (state.car.type_id && state.car.type_id !== true) {
			let type = state.car.types.find(item => item.id === state.car.type_id);
			return type.name;
		}
		return '';
	},

	carAge: state => {
		let now = moment();
		return now.diff(moment(state.car.first_registration), 'years', false);
	},

	roundedMileage: state => {
		let number = state.car.mileage > 10000 ? 10000 : 1000;

		return Math.round(state.car.mileage / number) * number;

	},

	validateIsCarSelected: (state, getters) => {
		return getters.make_id !== '' && getters.range_id !== '' && getters.type_id !== '' && getters.first_registration !== '' && getters.mileage !== '';
		// if (getters.make_id !== '' && getters.range_id !== '' && getters.type_id !== '' && getters.first_registration !== '' && getters.mileage !== '') {
		//     return (getters.qual_md_list.length && getters.qual_lt_list.length) && (getters.qual_md !== '' && getters.qual_lt !== '');
		// }
		// return false;
	},

	token: (state, getters) => {
		return state.car.token;
	},
};