<template>
  <div class="form-group">
    <div @click="toggle(item)">
      <label :for="'cservice' + item.id">
        {{item.name}} ({{Country.CURRENCY}} {{(item.price).toFixed(2)}})
        <span v-if="hasTooltip(item)" class="icon" v-tooltip="{content: getTooltip(item), trigger : 'hover click'}"><i
            class="fa fa-info-circle"></i></span>
      </label>

      <v-checkbox :loading="false" :checked="item.selected" :disabled="!item.exist"
                  :id="'cservice' + item.id"></v-checkbox>

    </div>
  </div>
</template>
<script>
	import VCheckbox from '@/components/forms/inputs/general/VCheckbox';
    import Country from "../../../../helpers/Country";

	export default {
		props: {
			item: Object
		},
        data(){
          return {
            Country: Country
          }
        },
       components: {VCheckbox},
		methods: {
			toggle(item) {
				this.$store.commit('toggleLabourCustom', item);
				this.$store.dispatch('getGarages').then(() => {
				});
			},
			hasTooltip(item) {
				return item.description !== null && item.description.length > 0
			},
			getTooltip(item) {
				return item.description;
			}
		}
	}
</script>