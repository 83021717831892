<template>
    <div id="app">
        <div class="loading-global" v-if="loading">
            <div class="icon"><i class="fa fa-spinner"></i></div>
        </div>
        <app-header></app-header>
        <main>
            <router-view :key="$route.fullPath"/>
        </main>
        <app-footer></app-footer>
    </div>
</template>

<script>
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';
import SessionStorage from "./helpers/SessionStorage";
import routes from "@/router/routes";

export default {
    data() {
        return {
            routes: routes
        }
    },
    page: {
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title;
            return title ? `${title} | ${window._repair_shop.name}` : window._repair_shop.name
        },
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
    },
    created() {

        if (this.$store.getters.offerLoaded === false) {

            SessionStorage.setItem('offer_token', window._offerToken);

            this.$store.dispatch('getOffer').then(() => {
                if (this.$store.state.step > 0) {
                    this.$router.push({name: this.routes[this.$store.state.step].name});
                }
            })
            .catch(error => {
                SessionStorage.removeItem('offer_token');
            })

        } else {
            SessionStorage.removeItem('offer_token');
        }

    },

    components: {'app-header': Header, 'app-footer': Footer},

};
</script>
<style src="@/assets/scss/app.scss" lang="scss"></style>
