<template>
    <header>
        <div class="container">
            <div class="logo">
                <router-link tag="a" :to="{name:'home'}">
                    <img :data-object-fit="setContain()" :src="logo" :alt="repairShopName">
                </router-link>
            </div>
            <div class="languages" v-if="step === 0 && languages.length > 1">
                <ul>
                    <li v-for="(language, index) in languages" :key="'language-' +index"
                        @click="setLang(language.locale)">{{language.name}}
                    </li>
                </ul>
            </div>
        </div>
    </header>
</template>
<script>

    export default {
        computed: {
            logo() {
                return window._repair_shop.logo;
            },
            repairShopName() {
                return window._repair_shop.name;
            },
            languages() {
                return window._repair_shop.languages;
            },
            step() {
                return this.$store.getters.step;
            },
        },
        methods: {
            setLang(value) {
                this.$store.dispatch('setLanguage', value);
                // this.$validator.localize(value);
                // this.$i18n.locale = value;
            },
            setContain(){
                if(window._repair_shop.group === 'AMAG'){
                  return 'contain';
                }
            }
        }
    }
</script>