<template>
  <div class="service-form">
    <div class="service-form-box">
      <official-services :list="officialServices"/>
    </div>
    <div class="service-form-box">
      <search-services :list="labourSearchServices" :preselectedOfficial="preselectedOfficialServices" @onServiceLoading="onServiceLoading"/>
    </div>
    <div class="form-group">
      <router-link :to="{name:'home'}" tag="a" class="back-link">
        <i class="fa fa-angle-left"></i>
        {{$t('form.button.back')}}
      </router-link>
      <button @click.prevent="submit" type="submit" class="pull-r"
              v-bind:class="{ 'disabled': submitDisabled||selectedLabourSearchServices }"
              :disabled="submitDisabled||selectedLabourSearchServices">{{$t('form.button.next')}}</button>
    </div>
  </div>
</template>
<script>
	import OfficialServices from '@/components/forms/groups/services/OfficialServices';
	import SearchServices from '@/components/forms/groups/services/SearchServices';

	export default {
		components: {OfficialServices, SearchServices},
		computed: {
			preselectedOfficialServices() {
				return this.$store.getters.preselectedServices.official;
			},
			officialServices() {
				return this.$store.getters.officialServices;
			},
			labourSearchServices() {
				let temp = this.$store.getters.tempSearch,
					term = this.$store.getters.term;
				if (temp.length > 0 || term.length > 0) return temp;

				return this.$store.getters.labourSearchServices;
			},
      selectedLabourSearchServices() {
			  return !this.$store.getters.labourSearchServices.filter(labour => labour.selected).length
      }
		},
    data: function () {
		  return {
		    submitDisabled: false
      }
    },
		methods: {
			submit() {
				this.$store.dispatch('getGarages').then(() => {
					this.$emit('submit');
				})
        .catch(error => {
          this.$emit('error', error);
        });
			},
      onServiceLoading (loading) {
			  this.submitDisabled = loading;
      }
		}
	}
</script>