<template>
    <div>
        <div>
            <form @submit.prevent="submit">
                <div class="form-group has-feedback" :class="hasError('token')">
                    <label for="token" v-html="$t('form.label.user_verify_token')"></label>
                    <input type="text" name="token" id="token" v-model="token" v-validate="{required:true}"
                           class="form-control" autocomplete="off" :placeholder="$t('form.placeholder.user_verify_token')">
                    <div v-if="hasError('token')">
                        <span class="form-control-feedback"></span>
                        <small class="help-block"> {{ errors.first('token') }}</small>
                    </div>
                </div>
                <div class="form-group text-center">
                    <button @click.prevent="submit" type="submit" v-bind:class="{ 'disabled': loading }"
                            v-html="$t('leads.verify_user.verify')" :disabled="loading"></button>
                </div>
            </form>

            <div class="resend-token">
                <div><span>{{$t('leads.verify_user.resend_token.not_received')}}</span></div>
                <div v-if="canSendAgain">
                    <a href="javascript:void(0)" @click="resendToken">{{$t('leads.verify_user.resend_token.send_again')}}</a>
                    | <a href="javascript:void(0)" @click="backToUserData">{{$t('leads.verify_user.resend_token.update_data')}}</a>
                </div>
                <div v-else>
                    <span>{{$t('leads.verify_user.resend_token.countdown', {time: countdown})}}</span>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import InputHasError from "@/mixins/InputHasError";
import countdown from "@/helpers/countdown";

export default {
    mixins: [InputHasError],

    data() {
        return {
            token: '',
            loading: false,
            canSendAgain: true,
            countdown: '00:30',
        }
    },

    methods: {
        submit() {
            this.$validator.validateAll().then(data => {
                if (data) {
                    this.loading = true;

                    this.$store.dispatch('tokenValidation', this.token)
                        .then(() => {
                            this.$emit('submit');
                        })
                        .catch(error => {
                            this.$emit('error', error);
                        })
                        .finally(() => {
                            this.loading = false
                        });
                }
            });
        },
        backToUserData() {
            this.$emit('backToUserData');
        },
        resendToken() {
            this.canSendAgain = false;

            countdown(0, 30,
                (val) => {
                    this.countdown = val;
                }, () => {
                    this.canSendAgain = true
                })

            this.$store.dispatch('saveUser')
        }
    }
}
</script>