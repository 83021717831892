let licencePlateFactory = () => {
	return {
		data: '',
		show_by_default: !!(window._repair_shop.showLicencePlate),
		show_by_user: true,
		multiple_vehicle: false,
		attempts: window._settings.licence_plate_attempts,
		status: null,
		exists: {
			make_id: false,
			range_id: false,
			type_id: false,
		}
	}
};

export default licencePlateFactory;