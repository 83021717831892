export const mutations = {
	globalLicencePlate: (state, payload) => {
		state.licence_plate.data = payload;
	},
	setExist: (state, payload) => {
		state.licence_plate.exist = payload;
	},
	setMultipleVehicleLP: (state, payload) => {
		state.licence_plate.multiple_vehicle = payload;
	},
	setShowByUserLP: (state, payload) => {
		state.licence_plate.show_by_user = payload;
	},
	setStatusLP: (state, payload) => {
		state.licence_plate.status = payload;
	},
	setAttemptsLP: (state, payload)=>{
		state.licence_plate.attempts = payload;
	},
	setExistsLPCar: (state, payload) => {
		Object.keys(state.licence_plate.exists).forEach((key) => {
			if (key in state.licence_plate.exists) {
				state.licence_plate.exists[key] = (payload[key] === null);
			}
		});
	}
};