import Vue from 'vue';
import {CREATE_OFFER, SAVE_USER} from "../../../consts/url";

export const actions = {

    createOffer: ({state, commit}) => {
        commit('loading', true);

        return Vue.axios[CREATE_OFFER.method](CREATE_OFFER.name, state.user).then(response => {
            // commit('setGarage', response.data.garage);
        }).finally(()=>{
            commit('loading', false);
        });

    },

    saveUser: ({state, commit}) => {
        return Vue.axios[SAVE_USER.method](SAVE_USER.name, state.user).then(response => {

        })

    },
};