import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import NProgress from 'nprogress/nprogress';
import routes from '@/router/routes';
import store from '../store/store';
import SessionStorage from "@/helpers/SessionStorage";


Vue.use(VueRouter);
Vue.use(VueMeta, {
    keyName: 'page',
});

const router = new VueRouter({
    routes,
    mode: 'hash',
    // mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    },
});

router.beforeResolve((routeTo, routeFrom, next) => {
    if (routeFrom.name) {
        NProgress.start();
    }
    next()
});

router.beforeEach((to, from, next) => {

    let requestOfferToken = () => !!(store.getters.token && SessionStorage.getItem('offer_token'))

    let requestCarData = () => !!store.getters.routeIsCarSelected;

    let requestServiceData = () => !!store.getters.routeIsServiceExist

    let requestHasLeadsAccess = () => !!window._repair_shop.leadAccess

    let requestUserVerified = () => !!store.getters.routeLeadsUserVerified

    let requestGarageData = () => !!store.getters.routeGarageExist

    let requestBookingDatesData = () => !!store.getters.routeBookingDatesExist

    let requestIRSRToken = () => !!store.getters.routeIRSRToken

    let requestUserData = () => !!store.getters.routeUserExist

    to.matched.some(record => {
        Promise.all(
            Object.keys(record.meta).map(meta => new Promise((resolve, reject) => eval(meta)() === record.meta[meta] ? resolve() : reject()))
        )
        .then(() => next())
        .catch(() => next(record.props.default.redirect))
    });
});

router.afterEach((routeTo, routeFrom) => {
    NProgress.done()
});

export default router;
