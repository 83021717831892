import store from "../store/store";
import * as Sentry from '@sentry/browser';
import SessionStorage from "../helpers/SessionStorage";

export class GeneralError extends Error {
    constructor() {
        super();
        // this.sendErrors();
    }

    sendErrors() {
        let data = {
            car: store.state.car,
            services: store.state.services,
            offer_token: SessionStorage.getItem('offer_token'),
        };

        Sentry.configureScope((scope) => {
            scope.setExtra("data", data);
        });

    }
}