import Vue from 'vue';
import {LIST_SERVICES, CHECK_SERVICE, SEARCH_SERVICE} from "@/consts/url";

export const actions = {
	getServices: ({state, rootState, commit, dispatch}) => {
		commit('loading', true);

		return Vue.axios[LIST_SERVICES.method](LIST_SERVICES.name, rootState.car.car).then(response => {
			commit('setOfficial', response.data.official);
			commit('setLabourSearch', response.data.labour.search);
			commit('setLabourCustom', response.data.labour.custom);

		}).then(() => {
			dispatch('submitPreselectedServices', rootState.preselected.services);
		}).finally(() => {
			commit('loading', false);
		});
	},

	submitPreselectedServices: ({getters, commit, dispatch}, payload) => {
		if (payload.official) dispatch('toggleAllOfficial', getters.officialServices);
		if (payload.search.length) dispatch('checkLabourSearch', getters.findLabourServicesById(payload.labour));
		if (payload.custom.length) commit('toggleMultipleLabourCustom', [payload.custom]);
	},

	toggleAllOfficial: ({state, rootState, commit, dispatch}, payload) => {
		commit('toggleAllOfficial');
	},

	checkLabourSearch: ({rootState, getters, commit, dispatch}, payload) => {
		commit('waiting', true);

		let car = {
			type_id: getters.type_id,
			qual_md: getters.qual_md,
			qual_lt: getters.qual_lt,
		};


		let promises = payload.map(service => {
			if (service.selected !== true) {

				commit('appendLabourSearch', service);

				return Vue.axios[CHECK_SERVICE.method](CHECK_SERVICE.name, {...car, service}).then(response => {
					commit('setLabourSearchService', {id: service.id, data: response.data, item: service});
				});
			} else {
				commit('unsetLabourSearchService', {service: service});
			}
		});

		return Promise.all(promises).finally(() => {
			commit('waiting', false);
		});
	},


	async validateOfficialServices({state, rootState, getters}) {
		let counter = 0;
		getters.officialServices.forEach(item => {
			if (item.selected === 0) counter++;
		});

		if (counter > 0) throw new Error('Not selected any official service.');
	},

	searchService: ({rootState, commit}) => {
		return Vue.axios[SEARCH_SERVICE.method](SEARCH_SERVICE.name, {search: rootState.services.search.term}).then(response => {
			commit('appendTempSearch', response.data);
		})
	},
};