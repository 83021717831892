<template>
    <div class="container lead-container">
        <div class="row">
            <div class="form-widget-content">
                <div class="col-md-6 widget-container">
                    <widget></widget>
                </div>
                <div class="col-md-6 termin-container">
                    <steps></steps>
                    <img src="/img/lead/lead-step-3.png" alt="Step 3.">
                </div>
            </div>
        </div>

        <popup v-if="showUserData" @close="showUserData = false">
            <user-lead-form @submit="submitSaveUser" @error="error" slot="body"></user-lead-form>
        </popup>

        <popup v-if="showTokenForm" @close="showTokenForm = false">
            <token-verification-lead-form @submit="submitVerifyUser" @backToUserData="backToUserData" @error="error" slot="body"></token-verification-lead-form>
        </popup>

    </div>
</template>
<script>
    import Widget from '@/components/layout/Widget';
    import Steps from '@/components/layout/Steps';
    import store from '../store/store';
    import {lang} from '@/lang';
    import Popup from "@/components/Popup";
    import UserLeadForm from "@/components/forms/UserLead";
    import TokenVerificationLeadForm from "@/components/forms/TokenVerificationLead";

    export default {
        page: {
            title: lang[store.getters.language].title.garage,
        },

        components: {Widget, Steps, Popup, UserLeadForm, TokenVerificationLeadForm},

        created() {
            if(this.$store.getters.routeLeadsUserVerified){
                this.$router.push({name: 'details'});
            }
            else {
                this.$store.dispatch('setStep', 2);
            }
        },

        data() {
            return {
                showUserData: true,
                showTokenForm: false,
            }
        },
        methods: {
            submitSaveUser() {
                this.showUserData = false;
                this.showTokenForm = true;
            },
            submitVerifyUser() {
                this.$store.commit('setLeadsVerified', true);
                this.$router.push({name: 'details'});
            },
            error(error) {
                this.$toastr("error", error.response.data.message, this.$t("toastr.error"));
            },
            backToUserData() {
                this.showUserData = true;
                this.showTokenForm = false;
            },
        }
    }
</script>