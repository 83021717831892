export default {
    header: {},
    footer: {
        privacy: 'Déclaration de confidentialité',
        agb: 'CGV'
    },
    currency: 'CHF',
    routes: {
        home: '/',
        services: '/prestations',
        leads: '/service-prix',
        details: '/date',
        user: '/données clients',
        thanks: '/merci',
    },
    title: {
        home: 'Sélectionner voiture',
        services: 'Sélectionner prestation',
        garage: 'Sélectionner date',
        user: 'Entrer données',
    },
    pages: {
        vehicle_title: 'Veuillez sélectionner votre véhicule :',
        service_title: 'Veuillez sélectionner les travaux souhaités :',
    },
    toastr: {
        info: 'Info',
        warning: 'Avertissement',
        success: 'Succès',
        error: 'Erreur',
    },
    defaults: {
        read_more: 'Plus',
        read_less: 'Moins'
    },
    form: {
        placeholder: {
            car_number_only_vin: 'Entrer numéro de châssis / VIN',
            car_number_only_swiss: 'Entrer numéro d’homologation',
            car_number_only_kba: 'Entrer numéro d’homologation',
            car_number_vin_swiss: 'Entrer le type de véhicule ou numéro de châssis / VIN',
            car_number_vin_kba: 'Entrer le type de véhicule ou numéro de châssis / VIN',
            vin: 'Entrer numéro de châssis',
            swiss: 'Entrer numéro d’homologation',
            licence_plate: 'ZH18341',
            make: 'Veuillez sélectionner la marque de la voiture',
            range: 'Veuillez sélectionner le modèle',
            type: 'Veuillez sélectionner le type',
            gear: 'Veuillez sélectionner la transmission',
            first_registration: 'Veuillez choisir une année de production de véhicule',
            qual_md: 'Veuillez sélectionner l’équipement',
            qual_lt: 'Veuillez sélectionner la carrosserie',
            mileage: 'Kilométrage',
            color: 'Couleur:',
            search_term: 'Recherche de prestation...',

            first_name: 'Veuillez entrer votre prénom',
            last_name: 'Veuillez entrer votre nom',
            email: 'Veuillez entrer votre adresse e-mail',
            phone: 'Veuillez entrer votre numéro de téléphone',
            address: 'Veuillez entrer votre adresse',

            comment: '',
            'user_verify_token': '112233'
        },
        label: {
            car_number_only_vin: 'Numéro de châssis / VIN',
            car_number_only_swiss: 'Numéro d’homologation',
            car_number_only_kba: 'Numéro d’homologation',
            car_number_vin_swiss: 'Numéro d’homologation ou numéro de châssis / VIN',
            car_number_vin_kba: 'Numéro d’homologation ou numéro de châssis / VIN',
            vin: 'Numéro de châssis',
            swiss: 'Numéro d’homologation',
            licence_plate: 'Veuillez saisir votre plaque d\'immatriculation',
            make: 'Marque :',
            range: 'Modèle :',
            type: 'Type :',
            gear: 'Transmission :',
            first_registration: 'Année de production de véhicule :',
            qual_md: 'Équipement :',
            qual_lt: 'Carrosserie :',
            mileage: 'Kilométrage :',
            color: 'Couleur:',

            first_name: 'Prénom',
            last_name: 'Nom',
            email: 'Adresse e-mail',
            phone: 'Numéro de téléphone',
            address: 'Adresse (facultatif)',

            comment: 'Remarque :',

            user_verify_token: 'Code de vérification',

            privacy_agb: `J’accepte la <a href="{privacy_link}" class="link-none-color underline" target="_blank">déclaration de confidentialité</a> et les 
                                             <a href="{agb_link}" class="link-none-color underline" target="_blank">CGV</a>.`,
        },
        button: {
            back: 'Précédent',
            next: 'Suivant',
            submit_car_number: 'Rechercher',
        }
    },

    home: {
        or: 'ou',
        vehicle: {
            info_vin_swiss: 'Les informations figurent sur la carte du véhicule au point 23/24.',
            goto_vin_swiss_link: 'Utiliser numéro d’homologation ou numéro de châssis',
            goto_manual_car_link: 'ou sélectionnez votre voiture',
            not_found_vin_swiss: 'Nous n\'avons malheureusement pas pu trouver votre véhicule. Veuillez vérifier la saisie ou sélectionner votre véhicule.',
        },
        licence_plate: {
            no_vehicle: 'Nous n\'avons pas pu trouver votre voiture. Veuillez remplir les informations ci-dessous manuellement.',
            multiple_vehicle: 'Veuillez sélectionner la couleur et la marque de votre voiture.',

            manual: 'Vous ne connaissez pas votre numéro de plaque?',
            enter: 'Saisie de la plaque d\'immatriculation',
            found_vehicle: 'Votre voiture a été identifié:',
            provide_half_vehicle: 'Veuillez indiquer des informations complémentaires.',
            provide_full_vehicle: 'Veuillez faire uns sélection supplémentaire',
            tooltip: 'L’identification du véhicule est réalisée à l’aide des sources suivantes: Office fédéral de la' +
                ' voirie, OFROU. (Version des données {monthYear})',
            source_provider: 'Source: Office fédéral des routes (OFROU) - Astra.',
        }
    },
    services: {
        official: {
            title: 'Prestation conforme aux indications du constructeur {make}',
            sub_title: 'Prestation pour {make} {range} / {mileage}km :',
            not_found: `<p>Nous n’avons pas pu trouver de prestation pour le véhicule sélectionné. <br> Veuillez sélectionner les travaux d’entretien ou contactez-nous par téléphone.</p>`,
            not_found_young: `<p> Nous n’avons pas pu trouver de prestation pour le véhicule sélectionné. <br> Veuillez sélectionner les travaux d’entretien ou contactez-nous par téléphone.</p>`
        },
        labour: {
            title: 'Sélectionner les travaux d’entretien :',
            title_if_preselected_official: 'Sélectionnez plus de services / fonctionne individuellement:',
            not_exist: 'Cette réparation n’est pas disponible. Nous pouvons vous proposer une offre personnelle pour votre véhicule.',
            not_found_term: 'Terme recherché non trouvé. Essayez un autre terme.',
            searching: 'Recherche...',
            already_selected: 'inclus dans la prestation',
        }
    },
    leads: {
        save_user: {
            save: 'sauver',
        },
        verify_user: {
            verify: 'Vérifier',
            resend_token: {
                not_received: 'Vous n\'avez pas reçu le code?',
                send_again: 'Cliquez ici pour renvoyer',
                update_data: 'Mettez à jour vos données',
                countdown: 'Renvoyer dans {time}',
            }
        }
    },
    details: {
        title: 'Vous avez sélectionné les travaux suivants :',
        additional_services: 'Sélectionner une offre supplémentaire :',
        works_parts: 'Travaux et pièces',
        included_in_service: ' inclus dans la prestation.',
        price_include_tax: 'Prix TTC',
        services: {
            required: 'Vous n’avez choisi aucune prestation.',
        },
        terminpicker: {
            title: 'Choisir une date de dépot du véhicule :',
            all_day: 'Libre',
            max_days: 'Vous avez déjà choisi 5 jours max.',
            disabled_days: 'La date choisie n’est pas disponible.',
            required: 'Veuillez choisir au moins une date.',
        },
        start_price: 'Prix fixe TTC:',
        discount_on_parts: 'Remise sur le matériel:',
        discount_on_brakes: 'Remise sur les freins:',
        discount_price: 'Prix total avec réduction:',
    },
    user: {
        book_now: 'Envoyer la demande',
        annotation: 'Une fois votre réservation faite, votre demande sera examinée par notre service clients. Cela peut durer un moment. Vous recevrez ensuite une confirmation personnelle par mail ou téléphone.',
    },
    thank_you: {
        title: 'Merci pour votre commande.',
        text: 'Une fois votre commande traitée dans notre système et vérifiée, vous recevrez une confirmation par mail ou téléphone.',
        button: 'RETOUR À LA PAGE D’ACCUEIL',
    },

    page_not_found: {
        text: 'Nous cherchons cette page mais nous ne la trouvons pas...',
        button: 'RETOUR À LA PAGE D’ACCUEIL',
    },

    validation: {
        custom: {
            make_id: {required: 'Veuillez sélectionner la marque de la voiture.'},
            range_id: {required: 'Veuillez sélectionner le modèle.'},
            type_id: {required: 'Veuillez sélectionner le type.'},
            first_registration: {required: ' Veuillez choisir une année de production de véhicule'},
            gear_id: {required: ' Veuillez sélectionner la transmission.'},
            qual_md: {required: 'Veuillez sélectionner l’équipement.'},
            qual_lt: {required: ' Veuillez sélectionner la carrosserie.'},
            mileage: {
                required: 'Veuillez sélectionner le kilométrage.',
                max: ' Veuillez sélectionner le kilométrage.'
            },
            licencePlate: {
                required: 'Veuillez saisir votre plaque d\'immatriculation',
                min: 'Veuillez saisir votre plaque d\'immatriculation',
                max: 'Veuillez saisir votre plaque d\'immatriculation'
            },
            zip: {
                required: ' Veuillez sélectionner le code postal.',
                min: ' Veuillez sélectionner le code postal.',
                max: ' Veuillez sélectionner le code postal.'
            },

            year: {required: 'Veuillez sélectionner la 1ère immatriculation.'},
            month: {required: 'Veuillez sélectionner la 1ère immatriculation.'},


            first_name: {required: 'Prénom doit être rempli.'},
            last_name: {required: 'Nom doit être rempli.'},
            phone: {required: 'Numéro de téléphone doit être rempli.'},
            email: {required: 'E-mail doit être rempli.'},
            address: {required: 'Adresse doit être rempli.'},

            privacy_agb: {required: 'Veuillez accepter les CGV et la déclaration de confidentialité.'},
        }
    },
    preparedServices: {
        oil: {
            name: null,
            second_name: null
        },
        zahriemen: {
            name: 'courroie de distribution',
            second_name: 'vérifier'
        },
        break_fluid: {
            name: null,
            second_name: null
        },
        airfilter: {
            name: null,
            second_name: null
        },
        klimafilter: {
            name: null,
            second_name: null
        },
    }
};