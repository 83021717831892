<template>
  <div class="v-datepicker">
    <div class="v-select" @click="open">
      <p v-if="!value"> {{placeholder}} <i class="fa fa-angle-down"></i></p>
      <p v-else> {{onlyYear(value)}} <i class="fa fa-angle-down"></i></p>
    </div>

    <div v-if="opened" class="picker">
      <div class="picker-content-container" v-on-click-outside="toggle">
        <div class="picker-header">
          <div class="picker-title">
            {{placeholder}}
          </div>
          <div class="picker-controls">
            <div class="arrow-left" @click="prev(canGoPrev())" :class="{disabled: canGoPrev()}">
              <i class="fa fa-angle-left"></i>
            </div>
            <div class="picker-name">
              <span v-if="showMonth && picked.year">{{picked.year}}</span>
              <span v-else> {{years[0]}} - {{years[years.length-1]}}</span>
            </div>
            <div class="arrow-right" @click="next(canGoNext())" :class="{disabled: canGoNext()}">
              <i class="fa fa-angle-right"></i>
            </div>
          </div>
        </div>

        <div class="picker-content">
          <ul v-if="!showMonth">
            <li v-for="(item, index) in years" :key="index" @click="pickYear(item)">{{item}}</li>
          </ul>
          <ul v-else>
            <li v-for="(item, index) in months" :key="index" @click="pickMonth(item.id)">{{item.name}}</li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
	import moment from "moment";

	export default {
		props: {
			placeholder: String,
			opened: Boolean,
			value: String
		},
		created: function() {
			this.setYears();
		},
		watch: {
			minYear: function() {
				this.setYears();
			},
			maxYear: function() {
				this.setYears();
			}
		},
		computed: {
			minYear: {
				get() {
					return this.$store.getters.productionYear.min
						? this.$store.getters.productionYear.min
						: 1985;
				}
			},
			maxYear: {
				get() {
					return this.$store.getters.productionYear.max
						? this.$store.getters.productionYear.max
						: moment().format("Y");
				}
			},
			months: {
				get() {
					let data = [];
					let months = moment.months();
					for (let i = 1; i < months.length + 1; i++) {
						let month = {
							id: i < 10 ? '0' + i : i.toString(),
							name: moment().month(i - 1).format('MMMM')
						};
						data.push(month);
					}

					return data;
				}
			},
		},

		data: () => ({
			maxShowYear: 12,
			years: [],
			showMonth: false,
			picked: {
				year: "",
				month: ""
			}
		}),
		methods: {
			setYears() {
				this.years = [];
				let counter = 0;
				while (counter < 12) {
					let data = moment(this.maxYear, "YYYY")
						.subtract(counter, "years")
						.format("Y");
					if (data >= this.minYear && data <= this.maxYear) {
						this.years.push(data);
					}
					counter++;
				}

				this.years.reverse();
			},
			canGoNext() {
				return parseInt(this.years[this.years.length - 1]) >= this.maxYear;
			},
			canGoPrev() {
				return this.years[0] <= this.minYear;
			},
			next(disabled) {
				if (disabled) {
					return; //this.$toastr("warning", 'Can\'t go next. ', this.$t("toastr.warning"));
				}
				if (this.showMonth) {
					this.showMonth = !this.showMonth;
				}
				let howMuchAdd = parseInt(this.years[this.years.length - 1]);
				this.years = [];

				let counter = 0;
				while (counter < 12) {
					let data = moment(this.maxYear, "YYYY")
						.subtract(counter, "years")
						.format("Y");
					if (data >= this.minYear && data <= this.maxYear) {
						this.years.push(data);
					}
					counter++;
				}

				this.years.reverse();
			},

			prev(disabled) {
				if (disabled) {
					return;
				}
				if (this.showMonth) {
					this.showMonth = !this.showMonth;
				}
				let howMuchAdd = this.maxYear - parseInt(this.years[0]);
				this.years = [];

				let counter = 0;
				while (counter < 12) {
					let data = moment(this.maxYear, "YYYY")
						.subtract(counter + howMuchAdd, "years")
						.format("Y");
					if (data >= this.minYear && data <= this.maxYear) {
						this.years.push(data);
					}
					counter++;
				}

				this.years.reverse();
			},
			pickYear(year) {
				this.picked.year = year;

				// Show only year
				let date = `${moment().format('M')}.${this.picked.year}`;
				this.$emit("input", date);
				this.$emit("change");
				this.toggle();

				// this.showMonth = true;
			},
			pickMonth(month) {
				this.picked.month = month;
				let date = `${this.picked.month}.${this.picked.year}`;
				this.showMonth = false;

				this.$emit("input", date);
				this.$emit("change");
				this.toggle();
			},
			toggle() {
				this.$emit("toggle");
			},
			open() {
				this.$emit("open");
			},
			onlyYear(date) {
				return date.substr(date.length - 4);
			}
		}
	};
</script>
