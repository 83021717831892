export default {
    header: {},
    footer: {
        privacy: 'Datenschutzerklärung',
        agb: 'AGB'
    },
    currency: 'CHF',
    routes: {
        home: '/',
        services: '/services',
        leads: '/service-preis',
        details: '/termin',
        user: '/kundendaten',
        thanks: '/danke',
    },
    title: {
        home: 'Auto selektieren',
        services: 'Service wählen',
        garage: 'Termin wählen',
        user: 'Daten eingeben',
    },
    pages: {
        vehicle_title: 'Bitte wählen Sie Ihr Fahrzeug aus:',
        service_title: 'Bitte wählen Sie Ihre gewünschten Arbeiten aus:',
    },
    toastr: {
        info: 'Info',
        warning: 'Warnung',
        success: 'Erfolg',
        error: 'Fehler',
    },
    defaults: {
        read_more: 'Mehr',
        read_less: 'Weniger'
    },
    form: {
        placeholder: {
            car_number_only_vin: 'Chassisnummer / VIN eingeben',
            car_number_only_swiss: 'Typenscheinnummer eingeben',
            car_number_only_kba: 'KBA nummer eingeben',
            car_number_vin_swiss: 'Typenschein oder Chassisnummer / VIN eingeben',
            car_number_vin_kba: 'z.B: 8566 AOO',
            vin: 'Fahrgestellnr. eingeben',
            swiss: 'Typenscheinnr. eingeben',
            licence_plate: 'z.B. ZH1432',
            make: 'Bitte Automarke auswählen',
            range: 'Bitte Modell auswählen',
            type: 'Bitte Ausführung auswählen',
            gear: 'Bitte Getriebeart auswählen',
            first_registration: 'Bitte 1. Inverkehrssetzung auswählen',
            qual_md: 'Bitte Ausstattungsvariante auswählen',
            qual_lt: 'Bitte Aufbau auswählen',
            mileage: 'Kilometer-Stand',
            color: 'Farbe',
            search_term: 'Servicesuche...',

            first_name: 'Bitte Vorname eingeben',
            last_name: 'Bitte Nachname eingeben',
            email: 'Bitte E-Mail-Adresse eingeben',
            phone: 'Bitte Telefonnummer eingeben',
            address: 'Bitte Adresse eingeben',

            comment: '',
            'user_verify_token': '112233'
        },
        label: {
            car_number_only_vin: 'Chassisnummer / VIN',
            car_number_only_swiss: 'Typenscheinnummer',
            car_number_only_kba: 'KBA nummer',
            car_number_vin_swiss: 'Typenschein oder Chassisnummer / VIN',
            car_number_vin_kba: 'Fahrzeugidentifikationsnummer (FIN) oder Schlüsselnummer (2.1 u. 2.2)',
            vin: 'Fahrgestellnummer',
            swiss: 'Typenscheinnummer',
            licence_plate: 'Bitte geben Sie Ihr Nummernschild ein',
            make: 'Marke:',
            range: 'Modell:',
            type: 'Ausführung:',
            gear: 'Getriebeart:',
            first_registration: '1. Inverkehrssetzung.',
            qual_md: 'Ausstattungsvariante:',
            qual_lt: 'Aufbau:',
            mileage: 'km-Stand:',
            color: 'Farbe:',

            first_name: 'Vorname',
            last_name: 'Nachname',
            email: 'E-Mail-Adresse',
            phone: 'Telefonnummer',
            address: 'Adresse (optional)',

            comment: 'Bemerkung:',

            user_verify_token: 'Bestätigungscode',

            privacy_agb: `Ich stimme den <a href="{privacy_link}" class="link-none-color underline" target="_blank">Datenschutzerklärung</a> und den 
                                             <a href="{agb_link}" class="link-none-color underline" target="_blank">AGB</a>'s zu.`,
        },
        button: {
            back: 'Zurück',
            next: 'Weiter',
            submit_car_number: 'Suchen',
        }
    },

    home: {
        or: 'oder',
        vehicle: {
            info_vin_swiss: 'Die Angaben finden Sie auf dem Fahrzeugausweis unter Punkt 23/24',
            goto_vin_swiss_link: 'Typenschein- oder Fahrgestellnummer benutzen',
            goto_manual_car_link: 'oder selektiere dein Auto',
            not_found_vin_swiss: 'Leider konnten wir Ihr Fahrzeug nicht finden. Bitte überprüfen Sie die Eingabe oder selektieren Sie das Fahrzeug.',
        },
        licence_plate: {
            no_vehicle: 'Wir konnten dein Fahrzeug nicht finden. Bitte ergänze die Informationen manuell.',
            multiple_vehicle: 'Bitte wähle die Farbe und Marke deines Fahrzeugs.',

            manual: 'Wissen Sie Ihr Nummernschild nicht?',
            enter: 'Nummernschild-Eingabe',
            found_vehicle: 'Ihr Fahrzeug wurde identifiziert',
            provide_half_vehicle: 'Bitte machen Sie eine zusätzliche Angabe',
            provide_full_vehicle: 'Treffen Sie eine zusätzliche Auswahl',
            tooltip: 'Die Fahrzeugidentifikation erfolgt mithilfe folgender Quelle: Bundesamt für Strassen ASTRA.' +
                ' (Datenstand {monthYear})',
            source_provider: 'Quelle: Bundesamt für Strassen ASTRA.',
        }
    },
    services: {
        official: {
            title: 'Service gemäss {make}-Herstellervorgaben',
            sub_title: 'Service für {make} {range} / {mileage}km:',
            not_found: `<p>Zum ausgewählten Fahrzeug konnten wir keinen Servicevorschlag finden. <br> Wählen Sie bitte die Wartungsarbeiten nachfolgend aus oder kontaktieren Sie uns telefonisch.</p>`,
            not_found_young: `<p>Zum ausgewählten Fahrzeug konnten wir keinen Servicevorschlag finden. <br> Wählen Sie bitte die Wartungsarbeiten nachfolgend aus oder kontaktieren Sie uns telefonisch.</p>`
        },
        labour: {
            title: 'Wartungsarbeiten einzeln auswählen:',
            title_if_preselected_official: 'Zusätzliche Wartungsarbeiten einzeln auswählen',
            not_exist: 'Diese Reparaturarbeit ist nicht verfügbar. Für Ihr Fahrzeug können wir ein persönliches Angebot erstellen.',
            not_found_term: 'Sucheingabe nicht vorhanden. Probiere einen anderen Begriff.',
            searching: 'Suchen...',
            already_selected: 'im Service enthalten',
        }
    },
    leads: {
        save_user: {
            save: 'Weiter',
        },
        verify_user: {
            verify: 'Bestätigen',
            resend_token: {
                not_received: 'Haben Sie den Code nicht erhalten?',
                send_again: 'Erneut senden',
                update_data: 'Daten aktualisieren',
                countdown: 'Senden Sie erneut in {Zeit}',
            }
        }
    },
    details: {
        title: 'Sie haben folgende Arbeiten ausgewählt:',
        additional_services: 'Zusatzangebot wählen:',
        works_parts: 'Arbeiten und Teile',
        included_in_service: ' Im Service enthalten.',
        price_include_tax: 'Preis inkl MwSt',
        services: {
            required: 'Du hast keinen Service ausgewählt.',
        },
        terminpicker: {
            title: 'Fahrzeug-Abgabe Termin wählen:',
            all_day: 'Bin flexibel',
            max_days: 'Du hast bereits max. 5 Tage ausgewählt.',
            disabled_days: 'Dein ausgewählter Termin ist nicht verfügbar.',
            required: 'Bitte wählen Sie mindestens einen Termin aus.',
        },
        start_price: 'Festpreis inkl. MwSt.:',
        discount_on_parts: 'Rabatt auf Material:',
        discount_on_brakes: 'Rabatt auf Bremsen:',
        discount_price: 'Total Preis inkl. Rabatt:',
    },
    user: {
        book_now: 'Anfrage absenden',
        annotation: 'Nach Ihrer Buchung wird Ihre Anfrage von unserem Kundendienstmitarbeiter geprüft. Dies kann einen Moment dauern. Anschliessend erhalten Sie eine persönliche Bestätigung per Mail oder Telefon.',
    },
    thank_you: {
        title: 'Vielen Dank für Ihren Auftrag.',
        text: 'Nachdem wir Ihre Anfrage im System bearbeitet und geprüft haben, erhalten Sie per Mail oder Telefon eine Bestätigung.',
        button: 'ZURÜCK ZUR HOMEPAGE',
    },

    page_not_found: {
        text: 'Ui...äähm...Wir suchen gerade nach dieser Seite – aber können sie irgendwie nicht finden...',
        button: 'ZURÜCK ZUR HOMEPAGE',
    },

    validation: {
        custom: {
            make_id: {required: 'Bitte wählen Sie die Automarke aus.'},
            range_id: {required: 'Bitte wählen Sie das Modell aus.'},
            type_id: {required: 'Bitte wählen Sie die Ausführung aus.'},
            first_registration: {required: 'Bitte wählen Sie das 1. Inverkehrssetzung des Fahrzeuges aus.'},
            gear_id: {required: 'Bitte wählen Sie die Getriebeart aus.'},
            qual_md: {required: 'Bitte wählen Sie die Ausstattungsvariante aus.'},
            qual_lt: {required: 'Bitte wählen Sie den Aufbau aus.'},
            mileage: {
                required: 'Bitte wählen Sie den Km-Stand aus.',
                max: 'Bitte wählen Sie den Km-Stand aus.'
            },
            licencePlate: {
                required: 'Bitte geben Sie Ihr Nummernschild ein',
                min: 'Bitte geben Sie Ihr Nummernschild ein',
                max: 'Bitte geben Sie Ihr Nummernschild ein',
            },
            zip: {
                required: 'Bitte wählen Sie die Postleitzahl aus.',
                min: 'Bitte wählen Sie die Postleitzahl aus.',
                max: 'Bitte wählen Sie die Postleitzahl aus.'
            },

            year: {required: 'Bitte wählen Sie die 1. Inverkehrsetzung aus.'},
            month: {required: 'Bitte wählen Sie die 1. Inverkehrsetzung aus.'},


            first_name: {required: 'Vorname muss ausgefüllt sein.'},
            last_name: {required: 'Name muss ausgefüllt sein.'},
            phone: {required: 'Telefonnummer muss ausgefüllt sein.'},
            email: {required: 'Email muss ausgefüllt sein.'},
            address: {required: 'Addrese muss ausgefüllt sein.'},

            privacy_agb: {required: 'Bitte AGB’s und Datenschutzerklärung akzeptieren.'},
        }
    },
    preparedServices: {
        oil: {
            name: null,
            second_name: null
        },
        zahriemen: {
            name: 'zahnriemen',
            second_name: 'prüfen'
        },
        break_fluid: {
            name: null,
            second_name: null
        },
        airfilter: {
            name: null,
            second_name: null
        },
        klimafilter: {
            name: null,
            second_name: null
        },
    }
};