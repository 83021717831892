<template>
    <div class="v-checkbox">
        <div v-if="checked && !loading" class="icon-check"><i class="fa fa-check"></i></div>
        <div v-if="loading" class="icon-loading"><i class="fa fa-cog"></i></div>
    </div>
</template>
<script>
    export default {
        props: {
            checked: Boolean,
            loading: Boolean,
        },
    }
</script>