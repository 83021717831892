let SessionStorage = {
    getItem(value) {
        return null;
    },
    setItem(key, value) {
        return null;
    },
    removeItem(key) {
        return null;
    },
}

const hasStorage = (function () {
    try {
        return !!sessionStorage.getItem;
    } catch (exception) {
        return false;
    }
}());

if (hasStorage) {
    SessionStorage = {
        getItem(key) {
            return sessionStorage.getItem(key);
        },
        setItem(key, value) {
            return sessionStorage.setItem(key, value);
        },
        removeItem(key) {
            return sessionStorage.removeItem(key);
        }
    }
}

export default SessionStorage;
