<template>
    <div class="official-services">
        <h3 v-html="$t('services.official.title', {make:make})"></h3>

        <div v-if="list.length">
            <div @click="toggle" class="checkbox-title">
                    <v-checkbox :loading="false" :checked="checked"></v-checkbox>
                <!-- loading="waiting" -->
                    <div class="sub-title"> {{$t('services.official.sub_title', {make:make, range: range, mileage:roundedMileage})}}</div>
            </div>
            <r-checkbox v-for="(item, index) in list" :key="index" :item="item"></r-checkbox>
        </div>
        <not-found-official v-else :make:="make" :mileage="mileage" :carAge="carAge"></not-found-official>
    </div>
</template>
<script>
    import OfficialCheckbox from '@/components/forms/inputs/services/OfficialCheckbox';
    import VCheckbox from '@/components/forms/inputs/general/VCheckbox';
    import NotFoundOfficial from '@/components/forms/groups/services/NotFoundOfficial';

    export default {
        props: {
            list: Array,
        },
        data: () => ({}),
        computed: {
            waiting() {
                return this.$store.getters.waiting;
            },
            checked() {
                return this.$store.getters.isSelectedOfficialServices;
            },
            make() {
                return this.$store.getters.make;
            },
            range() {
                return this.$store.getters.range;
            },
            mileage() {
                return this.$store.getters.mileage;
            },
            roundedMileage(){
                return this.$store.getters.roundedMileage;
            },
            carAge() {
                return this.$store.getters.carAge;
            }
        },
        components: {'r-checkbox': OfficialCheckbox, VCheckbox, NotFoundOfficial},
        methods: {
            toggle() {
                this.$store.dispatch('toggleAllOfficial', this.list);
            }
        }
    }
</script>