<template>
    <div class="container">
        <div class="row">
            <div class="form-widget-content">
                <div class="col-md-6 widget-container">
                    <widget></widget>
                </div>
                <div class="col-md-6">
                    <steps></steps>
                    <garage-form @submit="submit" @error="error"></garage-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GarageForm from '@/components/forms/Garage';
import Widget from '@/components/layout/Widget';
import Steps from '@/components/layout/Steps';
import store from '../store/store';
import {lang} from '@/lang';

export default {
    page: {
        title: lang[store.getters.language].title.garage,
    },
    components: {GarageForm, Widget, Steps},
    created() {
        this.$store.dispatch('setStep', 2);
        this.$scrollTo('.steps');
    },
    methods: {
        submit() {
            this.$router.push({name: 'user'});
        },
        error(error) {
            this.$toastr("error", error.response.data.message, this.$t("toastr.error"));

            if (error.response.status === 424) {
                this.$router.push({name: 'home'});
                this.$store.dispatch('resetState');
            }
        }
    }
}
</script>