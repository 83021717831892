<template>
    <div id="timepicker">
        <h4 v-html="$t('details.terminpicker.title')"></h4>
        <div class="calendar-header">
            <div class="arrow-left" @click="prev">
                <i class="fa fa-angle-left"></i>
            </div>
            <div class="arrow-right" @click="next">
                <i class="fa fa-angle-right"></i>
            </div>
        </div>
        <div class="calendar-main">
            <div class="calendar-week-list">
                <div class="calendar-week-day" v-for="(item, weekIndex) in myWeek" :key="weekIndex">

                    <div class="week-day-name">
                        <div :class="item.date === now ? 'bold': ''"> {{item.name}}</div>
                        <span>{{item.date.format('DD.MM.')}}</span>
                    </div>
                    <ul class="week-day-list">
                        <li @click="addAll(item.date.format('DD.MM.YYYY'), isDisabled(item.date))"
                            :class="{selected:isSelectedAll(item.date.format('DD.MM.YYYY'), hoursInDay), disabled: isDisabled(item.date)}">
                            {{$t('details.terminpicker.all_day')}}
                        </li>

                        <li v-for="(hour,index) in hoursInDay" :key="index"
                            @click="add(item.date.format('DD.MM.YYYY'), hour, isDisabled(item.date))"
                            :class="{selected: isSelected(item.date.format('DD.MM.YYYY'), hour), disabled: isDisabled(item.date)}">
                            {{hour}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";

    export default {
        props: ['waiting'],
        created: function () {
            if (this.bookingDates.length) {
                this.selected = this.bookingDates;
            }
            this.week(true, 1);
        },

        data: () => ({
            myWeek: [],
            fromTo: '',
            selected: [],
            countSelectedDays: 0,
            testDay: '',
        }),

        computed: {
            now() {
                return moment();
            },
            bookingDates: {
                get() {
                    return this.$store.getters.booking_dates;
                }
            },

            hoursInDay() {
                return [
                    '08.00-10.00',
                    '10.00-12.00',
                    '13.30-15.30',
                    '15.30-17.00',
                ];
            },

            firstAvailableDate() {
                let addDays = this.waiting + 1;
                return moment().addWorkdays(addDays);
            },

            firstDay() {
                let addDays = this.waiting + 1;
                return moment().addWorkdays(addDays);
            },

            dayName() {
                let days = moment.weekdays(true);
                days.splice(-2, 2);

                return days;
            },
        },


        methods: {
            prev() {
                this.week(false, 3);
            },

            next() {
                this.week(true, 3);
            },


            weekDates(add, num) {
                let day = this.firstDay;

                let data = [], myDay;
                myDay = this.myWeek.length && add ? moment(this.myWeek[this.myWeek.length - 1].date) : moment(day);

                if (add) {
                    data.push(myDay.addWorkdays(1));
                    data.push(myDay.addWorkdays(2));
                    data.push(myDay.addWorkdays(3));
                } else {
                    myDay = this.myWeek.length && !add ? moment(this.myWeek[0].date) : moment(day);
                    data.push(myDay.subtractWorkdays(3));
                    data.push(myDay.subtractWorkdays(2));
                    data.push(myDay.subtractWorkdays(1));
                }

                return data;
            },

            week(add, num) {
                let weekDates = this.weekDates(add, num);

                this.myWeek = [];

                this.fromTo = `${weekDates[0].format('DD.MM')} - ${weekDates[weekDates.length - 1].format('DD.MM.YYYY')}`;

                weekDates.forEach(item => {
                    this.myWeek.push({name: item.format('dddd'), date: item});
                });
            },

            add(date, time, disabled) {
                let existingDate = this.selected.find(item => item.date === date);
                if (!existingDate && this.countSelectedDays === 5) {
                    if (document.getElementById('overlay-bg') === null) {
                        this.$toastr("warning", this.$t('details.terminpicker.max_days'), this.$t("toastr.warning"));
                    }
                } else {
                    if (disabled) {
                        this.$toastr("warning", this.$t('details.terminpicker.disabled_days'), this.$t("toastr.warning"));
                    } else {
                        let exist = this.selected.find(item => item.date === date && time === item.time);
                        if (!exist) {
                            this.selected.push({date, time});
                        } else {
                            let index = this.selected.indexOf(exist);
                            this.selected.splice(index, 1);
                        }
                    }
                }
                this.countSelected();
                this.$store.commit('bookingDates', this.selected);
                this.$emit('booking', this.selected);
            },

            addAll(date, disabled) {
                if (disabled) {

                    this.$toastr("warning", this.$t('details.terminpicker.disabled_days'), this.$t("toastr.warning"));
                } else {
                    let temp = [], notSelected = [];
                    this.selected.forEach(item => {
                        if (item.date === date) {
                            temp.push(item.time);
                        }
                    });

                    if (!temp.length) notSelected = this.hoursInDay;
                    else {
                        this.hoursInDay.forEach(item => {
                            if (!temp.includes(item)) {
                                notSelected.push(item);
                            }
                        });
                    }
                    if (!notSelected.length) notSelected = this.hoursInDay;

                    notSelected.forEach(item => {
                        this.add(date, item);
                    });
                }
                this.countSelected();
            },

            isSelected(date, time) {
                let selected = false;
                this.selected.forEach(item => {
                    if (item.date === date && time === item.time) selected = true;
                });

                return selected;
            },

            isSelectedAll(date, time) {
                let selected = false, numOfSelected = 0;

                this.selected.forEach(item => {
                    if (date === item.date) numOfSelected++;
                });

                if (numOfSelected === time.length) selected = true;

                return selected;
            },

            isDisabled(date) {
                return date < this.firstAvailableDate;
            },
            countSelected() {
                return this.countSelectedDays = this.selected.map(item => item.date).filter((value, index, self) => self.indexOf(value) === index).length;
            }
        }
    }
</script>
