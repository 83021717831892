export const mutations = {
    setZip: (state, payload) => {
        state.address.zip = payload;
    },

    setAddress: (state, payload) => {
        state.address.list = payload;
        if (payload.length === 1) {
            state.address.city = payload[0].name;
            state.address.id = payload[0].id;
        }
    },

    resetAddress: () => {
    },
};
