const filterMakes = store => {
    store.subscribe((mutation, state) => {
        let type = mutation.type;
        if (type === 'setCar') {
            if (state.preselected.car.makes.length) {
                let intersectionMake = state.car.car.makes.filter(item => {
                    return state.preselected.car.makes.indexOf(item.id) > -1;
                });
                state.car.car.makes = intersectionMake;
                state.car.car.make_id = intersectionMake[0].id;
            }
            // if (state.preselected.car.ranges.length) {
            //     let intersectionRange = state.car.car.ranges.filter(item => {
            //         return state.preselected.car.ranges.indexOf(item.id) > -1;
            //     });
            //
            //     state.car.car.ranges = intersectionRange;
            //     state.car.car.range_id = intersectionRange[0].id;
            // }
        }
    })
};


export default filterMakes;