<template>
    <div class="custom-services" id="custom-services">
        <h3 v-html="$t('details.additional_services')"></h3>
        <checkbox v-for="(item, index) in list" :key="item.id" :item="item"></checkbox>
    </div>
</template>
<script>
    import CustomCheckbox from '@/components/forms/inputs/services/CustomCheckbox';

    export default {
        props: {
            list: Array,
        },
        components: {checkbox: CustomCheckbox},
    }
</script>