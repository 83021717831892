import Vue from 'vue';
import {LIST_GARAGES, SAVE_GARAGE} from "@/consts/url";

export const actions = {
    getGarages: ({state, commit, rootState}) => {
        commit('loading', true);

        return Vue.axios[LIST_GARAGES.method](LIST_GARAGES.name, rootState.services.services).then(response => {
            commit('setGarage', response.data.garage);
            // commit('setServicesAndPrices', {prev: response.data.official_prev, next: response.data.official_next, search: response.data.labour_search, custom:response.data.labour_custom})
        }).finally(() => {
            commit('loading', false);
        });
    },

    saveGarage: ({state, rootState, commit}) => {
        commit('loading', true);

        let data = {
            booking_dates: state.booking_dates,
            user_comment: rootState.user.user.comment,
        };

        return Vue.axios[SAVE_GARAGE.method](SAVE_GARAGE.name, data).then(response => {
            // commit('setGarage', response.data.garage);
            commit('setIRSRToken', response.data.token);
        }).finally(() => {
            commit('loading', false);
        });
    },

    async validateTermin({state}) {
        if (!state.booking_dates.length) {
            throw new Error('Not selected termin');
        }
    },

    async validateGarage({state, rootGetters}) {
        if (!rootGetters.isSelectedOfficialServices && !rootGetters.isSelectedLabourSearch && !rootGetters.isSelectedLabourCustom) {
            throw new Error();
        }
    },
};