import Vue from 'vue';
import {GET_CAR} from '@/consts/url';
import {RepairShopNotServicingCar} from '../../../exceptions/RepairShopNotServicingCar';
import {CarDataNotExisting} from '../../../exceptions/CarDataNotExisting';
import {GET_LICENCE_PLATE, MULTIPLE_VEHICLE_CHOUCE} from "../../../consts/url";
import SessionStorage from "../../../helpers/SessionStorage";

export const actions = {
	getCar: ({state, rootState, commit}) => {
		commit('loadingForm', true);

		let data = {...state.car, zip_id: rootState.address.address.id};

		return Vue.axios[GET_CAR.method](GET_CAR.name, data)
			.then(response => {
				let result = response.data;

				SessionStorage.setItem('offer_token', result.token);
				commit('setCar', result.car);
				if (!result.car_service_exist) {
					commit('isServicesExist', result.car_service);
				}
			})
			.catch(error => {
				throw new RepairShopNotServicingCar('Dieses Fahrzeug kann nicht gefunden werden. Bitte überprüfen Sie und versuchen Sie es erneut.');
				// throw new RepairShopNotServicingCar(error.response.data.message);
			})
			.finally(() => {
				commit('loadingForm', false);
				if (!rootState.isServicesExist) {
					throw new CarDataNotExisting('Car data not exist.');
				}
			});
	},
};