import 'es6-promise/auto';
import Vue from 'vue';
import App from './App.vue';
import store from './store/store';
import axios from 'axios';
import router from './router';
import VueAxios from 'vue-axios';
import VueLodash from 'vue-lodash';
// import VueAnalytics from 'vue-analytics';
import VTooltip from 'v-tooltip';
import VeeValidate, {Validator} from 'vee-validate';
import VueI18n from 'vue-i18n';
import {validation} from './lang/index';
import {lang} from './lang';
import {directive as onClickOutside} from 'vue-on-click-outside';
import VueScrollTo from 'vue-scrollto';
import VueToastr from './components/plugins/vue-toastr';
import Vue2TouchEvents from 'vue2-touch-events'
import ReadMore from './components/plugins/vue-read-more';
import * as Sentry from '@sentry/browser';
import lodash from 'lodash'

const moment = require('moment');

require('es6-object-assign').polyfill();
require('es6-promise').polyfill();
require('promise.prototype.finally').shim();
require('./config/axios');
require('./config/moment');

Vue.directive('on-click-outside', onClickOutside);

Vue.use(VueAxios, axios);
Vue.use(VueI18n);
Vue.use(VueLodash, {name: '$lodash', lodash: lodash});
Vue.use(VeeValidate, {
	events: 'change',
});

VeeValidate.Validator.localize(store.getters.language, validation[store.getters.language]);

// Vue.use(VueAnalytics, {id: 'UA-123456-78'});
Vue.use(VTooltip);
Vue.use(VueScrollTo);
Vue.use(VueToastr);
Vue.use(Vue2TouchEvents);
Vue.use(ReadMore);

VTooltip.defaultTrigger = 'hover click';

moment.locale(store.getters.language);
Vue.use(require('vue-moment'), {
	moment
});

const i18n = new VueI18n({
	locale: store.getters.language,
	messages: lang
});
if (process.env.NODE_ENV !== 'development') {
	Sentry.init({dsn: window._sentry_dsn});
}

if ('objectFit' in document.documentElement.style === false) {
	document.addEventListener('DOMContentLoaded', () => {
		Array.prototype.forEach.call(document.querySelectorAll('img[data-object-fit]'), image => {
			(image.runtimeStyle || image.style).background = `url("${image.src}") no-repeat 50%/${image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit')}`
			image.src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${image.width}' height='${image.height}'%3E%3C/svg%3E`
		})
	})
}

new Vue({
	i18n,
	router,
	store,
	render: h => h(App),
}).$mount('#app');
