<template>
    <div>
        <div :class="'toast-container ' + position" v-for="position in positions">
            <template v-for="(optionsArray, index) in list">
                <vue-toastr :data="optionsArray" :key="index" v-if="optionsArray.position === position"></vue-toastr>
            </template>
        </div>
    </div>
</template>
<script>
    import VueToastr from './vue-toastr.vue'

    export default {
        name: 'ToastrWrapper',
        components: {
            'vue-toastr': VueToastr
        },
        data() {
            return {
                positions: ['toast-top-right', 'toast-bottom-right', 'toast-bottom-left', 'toast-top-left', 'toast-top-full-width', 'toast-bottom-full-width', 'toast-top-center', 'toast-bottom-center'],
                list: []
            }
        },
        methods: {
            addOverlay(listLen) {
                if (!document.getElementById('overlay-bg') && (listLen === 0 || listLen > 1)) {
                    // document.getElementById("body").className = "overflow-hidden";
                    // let overlay = document.createElement('div');
                    // overlay.id = 'overlay-bg';
                    // document.body.appendChild(overlay);
                }
            },
            removeOverlay(listLen) {
                if (document.getElementById('overlay-bg') && listLen < 2) {
                    // document.getElementById("body").className = '';
                    // let overlay = document.getElementById('overlay-bg');
                    // overlay.parentNode.removeChild(overlay);
                }
            },
            addToast(data) {
                // this.addOverlay(this.list.length);
                this.list.push(data)
                // if have onCreated
                if (typeof data.onCreated !== 'undefined') {
                    // wait doom update after call cb
                    this.$nextTick(() => {
                        data.onCreated()
                    })
                }
            },
            removeToast(data) {
                this.removeOverlay(this.list.length);
                // if have onClosed
                if (data.onClosed !== undefined) {
                    data.onClosed()
                }
                this.list.splice(this.list.indexOf(data), 1)
            },
            Add(data) {
                return this.AddData(this.processObjectData(data))
            },
            AddData(data) {
                if (typeof data !== 'object') {
                    return
                }
                this.addToast(data)
            },
            processObjectData(data) {
                // if Object
                if (typeof data === 'object' && data.msg !== undefined) {
                    if (data.position === undefined) {
                        data.position = this.defaultPosition
                    }
                    if (data.type === undefined) {
                        data.type = this.defaultType
                    }
                    if (data.timeout === undefined) {
                        data.timeout = this.defaultTimeout
                    }
                    if (data.closeOnHover === undefined) {
                        data.closeOnHover = this.defaultCloseOnHover
                    }
                    return data
                }
                // if String
                return {
                    msg: data.toString(),
                    position: this.defaultPosition,
                    type: this.defaultType,
                    timeout: this.defaultTimeout,
                    closeOnHover: this.defaultCloseOnHover
                }
            },
            error(msg, title) {
                let data = this.processObjectData(msg)
                data['type'] = 'error'
                if (title !== undefined) {
                    data['title'] = title
                }
                return this.AddData(data)
            },
            success(msg, title) {
                let data = this.processObjectData(msg)
                data['type'] = 'success'
                if (title !== undefined) {
                    data['title'] = title
                }
                return this.AddData(data)
            },
            warning(msg, title) {
                let data = this.processObjectData(msg)
                data['type'] = 'warning'
                if (title !== undefined) {
                    data['title'] = title
                }
                return this.AddData(data)
            },
            info(msg, title) {
                let data = this.processObjectData(msg)
                data['type'] = 'info'
                if (title !== undefined) {
                    data['title'] = title
                }
                return this.AddData(data)
            },
            Close(data) {
                this.removeToast(data)
            }
        }
    }
</script>