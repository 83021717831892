import {lang} from '../../lang';
import Vue from 'vue';

const toggleLabourIfOfficial = store => {
	store.subscribeAction((action, state) => {
			if (action.type === 'toggleAllOfficial') {

				let selectedServices = [];
				let servicesToCheck = [];
				let serviceToChangeAlreadySelected = [];

				// Services to check
				const preparedServices = [
					{
						id: 76,
						name: lang[store.getters.language].preparedServices.zahriemen.name,
						second_name: lang[store.getters.language].preparedServices.zahriemen.second_name,
					},
					{
						id: 444,
						name: lang[store.getters.language].preparedServices.oil.name,
						second_name: lang[store.getters.language].preparedServices.oil.second_name,
					},
					{
						id: 451,
						name: lang[store.getters.language].preparedServices.break_fluid.name,
						second_name: lang[store.getters.language].preparedServices.break_fluid.second_name,
					},
					{
						id: 190,
						name: lang[store.getters.language].preparedServices.airfilter.name,
						second_name: lang[store.getters.language].preparedServices.airfilter.second_name,
					},
					{
						id: 435,
						name: lang[store.getters.language].preparedServices.klimafilter.name,
						second_name: lang[store.getters.language].preparedServices.klimafilter.second_name,
					}
				];


				// Get services to check from labour
				for (let labourService of state.services.services.labour.search) {
					for (let preparedService of preparedServices) {
						if ((labourService.name.toLowerCase()).includes(preparedService.name) || labourService.item_mp_id === preparedService.id) {
							selectedServices.push({...labourService, prepared: preparedService});
						}
					}
				}


				for (let service of selectedServices) {
					for (let payload of action.payload) {
						if (checkByName(payload, service) || checkById(payload, service)) {
							service.selected === payload.selected ? servicesToCheck.push(service) : serviceToChangeAlreadySelected.push(service);
						} else {
							for (let child of payload.childs) {
								if (checkByChildName(child, service) || checkByChildId(child, service)) {
									service.selected === payload.selected ? servicesToCheck.push(service) : serviceToChangeAlreadySelected.push(service);
								}
							}
						}
					}
				}

				// Filter unique
				let list = Vue.$lodash.uniq(servicesToCheck);

				// Set selected labours
				store.dispatch('checkLabourSearch', list).then(() => {
					state.services.services.labour.search.forEach(item => {
						let service = list.find(selected => selected.id === item.id);
						if (service) {
							item.already_selected = !item.already_selected;
						}

						serviceToChangeAlreadySelected.forEach(selected => {
							if (selected.id === item.id) {
								item.already_selected = true;
							}
						});
					});
				});
			}
		}
	)
};

function checkByName(official, labour) {
	return official.ItemMpText.toLowerCase().includes(labour.prepared.name) && !official.ItemMpText.toLowerCase().includes(labour.prepared.second_name)
}

function checkById(official, labour) {
	return official.ItemMpId === labour.prepared.id && official.KorId !== 9;
}

function checkByChildName(officialChild, labour) {
	return officialChild.serviceGroupName.toLowerCase().includes(labour.prepared.name)
}

function checkByChildId(officialChild, labour) {
	return officialChild.serviceParentItemMpId === labour.prepared.id;
}

export default toggleLabourIfOfficial;