let mixin = {
    methods: {
        IML(event, num, val) {
            if (event.charCode !== 0) {
                val = val !== null ? val : '';
                num === val.length ? event.preventDefault() : true;
            }
        }
    }
};

export default mixin;
