const GET_LICENCE_PLATE = {method: 'post', name: 'get-licence-plate'};
const MULTIPLE_VEHICLE_CHOICE = {method: 'post', name: 'multiple-vehicle-choice'};
const LIST_ZIP = {method: 'get', name: 'zip/'};
const GET_CAR = {method: 'post', name: 'get-car'};
const LIST_SERVICES = {method: 'post', name: 'list-services'};
const CHECK_SERVICE = {method: 'post', name: 'check-service'};
const SEARCH_SERVICE = {method: 'post', name: 'search-service'};
const LIST_GARAGES = {method: 'post', name: 'list-garages'};
const SAVE_GARAGE = {method: 'post', name: 'save-garage'};
const CREATE_OFFER = {method: 'post', name: 'save-offer'};
const SAVE_USER = {method: 'post', name: 'lead/save-user'};
const VERIFY_USER = {method: 'post', name: 'lead/verify-user'};
const STEP = {method: 'post', name: 'steps'};
const GET_OFFER = {method: 'post', name: 'get-offer'};

export {
    GET_LICENCE_PLATE,
    MULTIPLE_VEHICLE_CHOICE,
    LIST_ZIP,
    GET_CAR,
    LIST_SERVICES,
    CHECK_SERVICE,
    SEARCH_SERVICE,
    LIST_GARAGES,
    SAVE_GARAGE,
    SAVE_USER,
    CREATE_OFFER,
    VERIFY_USER,
    STEP,
    GET_OFFER
}