<template>
  <div class="widget">
    <h1><strong v-html="headingH1"></strong></h1>
    <img :src="headingImage" :alt="repairShopName">
    <div class="widget-book-list" :class="{'has-widget-image' : hasWidgetImage}">
      <h2><strong v-html="headingH2"></strong></h2>
      <div v-html="headlineList"></div>
      <div class="widget-book-list-image" v-if="hasWidgetImage">
        <img :src="widgetImage" :alt="repairShopName">
      </div>
    </div>
  </div>
</template>
<script>
	export default {
		computed: {
			repairShopName() {
				return window._repair_shop.name;
			},
			headingImage() {
				return window._headline_image;
			},
			headingH1() {
				return window._headline_h1;
			},
			headingH2() {
				return window._headline_h2;
			},
			headlineList() {
				return window._headline_list;
			},
			widgetImage() {
				return window._repair_shop.widgetImage;
			},
			hasWidgetImage() {
				return !!window._repair_shop.widgetImage;
			},
		}
	};
</script>