import axios from 'axios';
import store from '../store/store';
import * as Sentry from '@sentry/browser';
import SessionStorage from "../helpers/SessionStorage";

axios.defaults.baseURL = window._api_url;

axios.interceptors.request.use(function (config) {
    if (window._repair_shop.token) {
        config.headers.RepairShopToken = window._repair_shop.token;
    }

    config.headers.Language = store.getters.language;

    if (store.getters.token && SessionStorage.getItem('offer_token') !== store.getters.token) {
        SessionStorage.setItem('offer_token', store.getters.token);
        config.headers.OfferToken = store.getters.token;
    }
    else if (SessionStorage.getItem('offer_token') !== 'undefined' && SessionStorage.getItem('offer_token') !== null) {
        config.headers.OfferToken = SessionStorage.getItem('offer_token');
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});


axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    let data = {
        car: store.state.car,
        services: store.state.services,
        offer_token: SessionStorage.getItem('offer_token'),
    };
    Sentry.configureScope((scope) => {
        scope.setExtra("data", {...data, backend: error});
    });
    Sentry.captureMessage('Backend return error.');

    return Promise.reject(error);
});