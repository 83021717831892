<template>
  <div>
    <p>{{ $t('home.licence_plate.enter') }}</p>
    <div class="vehicle-form-box">
      <loader></loader>
      <div class="form-group has-feedback" :class="hasError('licencePlate')">
        <label for="licencePlate">
          {{ $t('form.label.licence_plate') }}
          <span class="icon" v-tooltip="{content: getTooltip, trigger : 'hover click'}"><i
              class="fa fa-info-circle"></i></span>
        </label>
        <input type="text" name="licencePlate" id="licencePlate" v-model="licencePlate"
               v-validate="{required:true, min:3, regex: /[A-Za-z]{2}[0-9]{1}/}" class="form-control"
               :placeholder="$t('form.placeholder.licence_plate')" autocomplete="off">
        <div v-if="hasError('licencePlate')">
          <span class="form-control-feedback"></span>
          <small class="help-block"> {{ errors.first('licencePlate') }}</small>
        </div>
      </div>

      <div class="form-group has-feedback" :class="hasError('mileage')">
        <label for="mileage" v-html="$t('form.label.mileage')"></label>
        <input type="number" name="mileage" id="mileage" v-model="mileage"
               @keypress="DDE + IML($event, 6, mileage)"
               v-validate="{required:true, max:6, min:0, regex:'^[0-9]+'}"
               class="form-control" min="0"
               :placeholder="$t('form.placeholder.mileage')" autocomplete="off">
        <div v-if="hasError('mileage')">
          <span class="form-control-feedback"></span>
          <small class="help-block"> {{ errors.first('mileage') }}</small>
        </div>
      </div>
      <a href="javascript:void(0)" @click="goToManual()" class="manual-pick">{{ $t('home.licence_plate.manual') }}</a>
    </div>
    <div class="form-group">
      <button v-if="false" @click.stop="back" class="pull-l">{{ $t('form.button.back') }}</button>
      <button @click.stop="submit" class="pull-r">{{ $t('form.button.next') }}</button>
    </div>
  </div>
</template>
<script>
import InputHasError from "@/mixins/InputHasError";
import InputMaxLength from "@/mixins/InputMaxLength";
import DisableDotExponential from "@/mixins/DisableDotExponential";
import {mapGetters} from 'vuex';
import Loader from '@/components/Loader';
import LicencePlateBox from '@/components/forms/licence-plate/LicencePlateBox';
import {RepairShopNotServicingCar} from "../../../exceptions/RepairShopNotServicingCar";
import moment from 'moment';

export default {
  mixins: [InputHasError, InputMaxLength, DisableDotExponential],
  components: {Loader, LicencePlateBox},
  computed: {
    ...mapGetters(['showByUserLP']),
    multipleVehicle: {
      get() {
        return this.$store.getters.multipleVehicleLP;
      }
    },
    licencePlate: {
      get() {
        return this.$store.getters.globalLicencePlate;
      },
      set(val) {
        this.$store.commit("globalLicencePlate", val);
      }
    },
    mileage: {
      get() {
        return this.$store.getters.mileage;
      },
      set(val) {
        this.$store.commit("mileage", val);
      }
    },
  },
  methods: {
    goToManual() {
      this.$store.dispatch('goManualVehicle');
    },
    getTooltip() {
      let tooltip = this.$t('home.licence_plate.tooltip', {
        monthYear:
            moment().subtract(1, 'months').format('MMMM Y')
      });


      return `${tooltip}`;
    },
    submit() {
      this.$validator.validateAll().then(data => {
        if (data) {
          this.$store.dispatch('getCarByLicencePlate').catch(error => {
            if (error instanceof RepairShopNotServicingCar) {
              this.$toastr("error", error.message, this.$t("toastr.error"));
            }
            // if(error instanceof  CarDataForLPNotExisting){
            // 	this.$toastr('error', this.$t('home.licence_plate.no_vehicle'), this.$t("toastr.error"));
            // }
          });
        }
      });
    }
  }
}
</script>