import Vue from 'vue';
import user from '@/factory/user';

export const mutations = {
    first_name: (state, payload) => {
        state.user.first_name = payload;
    },
    last_name: (state, payload) => {
        state.user.last_name = payload;
    },
    email: (state, payload) => {
        state.user.email = payload;
    },
    phone: (state, payload) => {
        state.user.phone = payload;
    },
    address: (state, payload) => {
        state.user.address = payload;
    },
    comment: (state, payload) => {
        state.user.comment = payload;
    },

    setUser: (state, payload) => {
        state.user = Vue.$lodash.defaults(payload, user());
    },
};