let mixin = {
    computed: {
        first_name: {
            get() {
                return this.$store.getters.first_name;
            },
            set(val) {
                this.$store.commit("first_name", val);
            }
        },
        last_name: {
            get() {
                return this.$store.getters.last_name;
            },
            set(val) {
                this.$store.commit("last_name", val);
            }
        },
        email: {
            get() {
                return this.$store.getters.email;
            },
            set(val) {
                this.$store.commit("email", val);
            }
        },
        phone: {
            get() {
                return this.$store.getters.phone;
            },
            set(val) {
                this.$store.commit("phone", val);
            }
        },
        address: {
            get() {
                return this.$store.getters.address;
            },
            set(val) {
                this.$store.commit("address", val);
            }
        },
    },
    methods: {}
};

export default mixin;
